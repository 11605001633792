.Location{
	padding-top:0;
	overflow:hidden;
	margin: 0 auto;
	margin-top: 4rem;
	text-align: center;
    &__titleSection{
        margin-bottom:3rem;
		@include HeadFont48;
        @media(min-width:576px){
            padding-bottom:0px;
        } 
    }	 
	&__subtitleSection{
		@include SubtitleFont14;
	}
}