.pageRooms{
	
	background-color: #E8E8E8;
    margin-top: 0;
    padding-top: 3rem;
    &__titleSection{
		margin-top: .6rem;
        margin-bottom:3rem;
		color: $headers;
		text-transform: none;
		@include HeadFont48;
		font-weight: 700;
        @media(min-width:576px){
            padding-bottom:0px;
        } 
        text-align: center;
    }	 
	&__subtitleSection{
		@include SubtitleFont14;
	}

	&__Container{
		background: #fff;
		&__icons{
			text-align: center;
			display: flex;
			align-items: center;
			width: 70%;
			margin: 3rem auto 2rem;
			p {
                @include MontserratFont;
                font-size: 18px;
                font-weight: 700;
				margin: 0 auto;
				img{
					width: 21px;
					height: 21px;
					margin-right: 10px;
				}
				@media(max-width:400px){
					display: flex;
					flex-direction: column;
					align-items: center;
				}
			}
		}
		&__Opis{
			@include MontserratFont;
			padding: 0 1rem;
            @media(min-width:992px){
                padding: 0 6rem;
            }
			@include BodyFont;
			font-weight: 300;
		}
		&__Callendar{
			text-align: center;
			img.logo{
					position: relative;
					top: 16px;
					z-index: 21;
					text-align: center;
			}
			.reservationPanel{
				&.granatowy{
					border: 1px solid #0c41aa;
					display: block;
					button{
						font-weight: 500;
						color: #fff;
						background-color:#0c41aa!important;
					}
					button{
						font-weight: 500;
						color: #fff;
						background-color:#0c41aa!important;
					}
					#calcurrent {
						background-color: #0c41aa!important;
						border: 1px solid #0c41aa!important;
					}
					#calroot {
						background-color: #0c41aa;
						border: 5px solid #0c41aa;
					}
					#calhead {
						background-color: #0c41aa;
					}
				}
				&.rozowy{
					border: 1px solid #C61A59;
					display: block;
					button{
						font-weight: 500;
						color: #fff;
						background-color:#C61A59!important;
					}
					#calcurrent {
						background-color: #c61a59!important;
						border: 1px solid #c61a59!important;
					}
					#calroot {
						background-color: #c61a59;
						border: 5px solid #c61a59;
					}
					#calhead {
						background-color: #c61a59;
					}
				}
				&.pomaranczowy{
					border: 1px solid #e8510f;
					display: block;
					button{
						font-weight: 500;
						color: #fff;
						background-color:#e8510f!important;
					}
					#calcurrent {
						background-color: #e8510f!important;
						border: 1px solid #e8510f!important;
					}
					#calroot {
						background-color: #e8510f;
						border: 5px solid #e8510f;
					}
					#calhead {
						background-color: #e8510f;
					}
				}
				&.zielony{
					border: 1px solid #0d8473;
					display: block;
					button{
						font-weight: 500;
						color: #fff;
						background-color:#0d8473!important;
					}
					#calcurrent {
						background-color: #0d8473!important;
						border: 1px solid #0d8473!important;
					}
					#calroot {
						background-color: #0d8473;
						border: 5px solid #0d8473;
					}
					#calhead {
						background-color: #0d8473;
					}
				}
				&.zolty{
					border: 1px solid #f2a50a;
					display: block;
					button{
						font-weight: 500;
						color: #000;
						background-color:#f2a50a!important;
					}
					#calcurrent {
						background-color: #f2a50a!important;
						border: 1px solid #f2a50a!important;
					}
					#calroot {
						background-color: #f2a50a;
						border: 5px solid #f2a50a;
					}
					#calhead {
						background-color: #f2a50a;
					}
				}
				background: #fff;
				
				margin: 0 auto;
				padding:1rem;
				/* max-width: 235px;
				max-width: 285px; */
				max-width: 400px;
		
				@media(min-width:576px){
					display:block;
				}
				@media(max-width:410px){
					max-width: 335px;
				}
		
				.form-group {
					display: block;
					margin-bottom: .3rem;
					margin-bottom: .7rem;
					/* width: 200px;
					width: 260px; */
					width: 400px;
					@media(max-width:410px){
						width: unset;
					}
				}
				.-form{
					label{
						@include MontserratFont;
						display: block;
						font-size: 8px;
						font-weight: 700;
						/* font-size: 12px; */
						font-size: 16px;
						font-weight: 500;
						color: #969696;
						padding: 5px 0 0 10px;
						text-align: left;
						@media(max-width:410px){
							font-size: 14px;
						}
					}
					justify-content: center;
		
					input, select{
						margin:0;
						background-color:#E6E6E6;
						border:0;
						outline:0 !important;
						box-shadow: none !important;
						@include MontserratFont;
						/* font-size: 12px; */
						font-size: 16px;
						font-weight: 500;
						color: #2D2D2D;
						border-radius: 0;
						padding: 0 10px 0 10px;
						padding: 20px 10px;
						cursor:pointer;
						width: 100%;
						min-height: 35px;
						@media(max-width:410px){
							font-size: 14px;
						}
		
						@media(min-width:767px){
							font-size: 16px;
						}
		
						@media(min-width:800px){
						}
		
						@media(min-width:870px){
							font-size: 16px;
						}
		
						@media(min-width:1300px){
						}
		
						&:focus{
							background-color:#fff;
							border:0;
							outline:0 !important;
							box-shadow: none !important;
							color:#000;
						}
						
						&.inputDateIn{
							background-image:url('../images/kalendarz.svg');
							background-repeat:no-repeat;
							background-position:90% center;
							background-size: 22px 22px;
						}
		
						&.inputDateOut{
							background-image:url('../images/kalendarz.svg');
							background-repeat:no-repeat;
							background-position:90% center;
							background-size: 22px 22px;
						}
		
						&.promocode{
							background-image:url('../images/promoCode_bg.png');
							background-repeat:no-repeat;
							background-position:90% center;
						}
		
					}
					
					select{
						option{
							padding:5px;
						}
						@include formFont;
					}
		
					button{
						@include MontserratFont;
						font-size: 13px;
						font-weight: 500;
						color: #fff;
						background-color:#C61A59;
						display:block !important;
						border-radius: 0;
						padding: 0;
						text-transform: uppercase;
						text-align: center;
						letter-spacing: 3px;
						width: 200px;
						height: 55px;
						margin: 20px auto 3px !important;
		
						@media(max-width:1200px){
							width: 150px;
							height: 50px;
						}
						@media(min-width:1200px){
						}
						
						@media(min-width:1250px){
						}
						&:before {				
							border: none;
							top: 5px;
							right: 5px;
							bottom: 5px;
							left: 5px;
						}
		
					}
				}
		
			}
		}
	}

	&__Udogodnienia{
		@media(min-width:992px){
			padding: 2rem 7rem;
		}
		padding: 2rem 1rem;
		&__title{
			margin-top: 4rem;
			.ApartamentyLista__titleSection {
				margin-bottom: 1rem;
			}
		}
		&__Item{			
			@include MontserratFont;
			@include BodyFont;
			font-weight: 400;
			color: #666;
			margin-bottom: 3rem;
			span.img{
				width: 100px;
				text-align: center;
			}
			span.opis{
				text-align: left;
				width: 150px;
			}
			img{
				width: auto;
				height: 44px;
			}
		}
		&__Items{
			display: flex;
			align-items: center;
		}
	}

	&__OtherLocations{
		margin-top: 4rem;
	}
		
	
}

#gallery-carousel {
	position: relative;
	margin-top: 3rem;
	.gallery-image {
		img{
			max-height: 400px;
			width: auto;
		}
	}
	.owl-theme .owl-nav {
		position: absolute;
		left: 0;
		right: 0;
		top: 40%;
	}
	.owl-theme .owl-nav [class*="owl-"] {
		color: #fff;
		font-size: 1.4375rem;
		margin: 0;
		padding: 5px;
		background: 0 0;
		display: inline-block;
		cursor: pointer;
		border-radius: 0;
		position: absolute;
		transition: all .4s ease 0s;
	}
	.owl-prev {
		left: 23.75vw;
	}
	.owl-next {
		right: 23.75vw;
	}
	.owl-prev svg {
		width: 4rem;
		height: 4rem;
		transform: rotate(180deg);
	}
}