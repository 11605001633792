.pageGallery{

    #filters{
        display: flex;
        justify-content: center;
        flex-wrap:wrap;
        @media(max-width:450px){
            flex-direction: column;
            text-align: center;
        }

        .button{
            color:#5c5d60;
            margin:4px 15px;
            min-height: 35px;
            font-size:16px;

            @media(min-width:992px){
                font-size:16px;
                margin:8px 15px;
            }

            @media(min-width:1200px){
                font-size:18px;
            }

            &::after{
                content:'';
                opacity:0;
                width:100%;
                height:2px;
                background-color: $ultraLightGrey;
                margin-top:3px;
                transition:.3s;
                display: block;
            }

            &.activeItem{
                &::after{
                   opacity:1;
                   
                }
            }

            &:hover{
                cursor:pointer;
            }
        }
    }

    .grid{
        min-height: 500px;
        .element-item{
            margin-bottom:20px;
            height:auto;
            position:relative;
            overflow:hidden;
            height:300px;
            
            // MAX WIDTH
            @media(max-width:768px){
                width: 100%;
                // left: 50% !important;
                // position:static !important;
            }

            @media(min-width:576px){
                width:350px;
            }

            @media(min-width:1200px){
                width:400px;
            }

            @media(min-width:1400px){
                width:440px;
                
            }

            img{
                position:absolute;
                left:50%;
                top:50%;
                transform: translate(-50%, -50%);
                
                min-width: 100%;
                min-height:100%;
                // border-left:15px solid #fff;
                // border-right:15px solid #fff;
                margin:0 20px;
            }

        }
    }
    
}