.pageContact{
    background-color: #E8E8E8;
    margin-top: 0;
    padding-top: 3rem;
    &__titleSection{
		margin-top: .6rem;
        margin-bottom:3rem;
		color: $headers;
		text-transform: none;
		@include HeadFont48;
		font-weight: 700;
        @media(min-width:576px){
            padding-bottom:0px;
        } 
        text-align: center;
    }	 
	&__subtitleSection{
		@include SubtitleFont14;
	}
	 &__Container{
		background: #fff;
        padding-top: 3rem;
        padding-bottom: 3rem;
		@media(min-width:993px){
			padding-left: 8rem;
			padding-right: 8rem;
		}
        @include MontserratFont;
        font-size: 14px;
        font-weight: 300;
        margin: 1rem auto 2rem;
		.textWrap{
			.textBox{
				a{
					// color: #003852;
					// font-size:14px;
					color: #000;
				}
			}
		}
		
		.icon{
			width:50px;
			@media(max-width:992px){
				width:20px;
			}

			img{
				width:50px;
				@media(max-width:992px){
					width:20px;
				}
			}
		}

		.text{
			p{
				padding-top:2px !important; 
				text-align: left !important;
				font-size:15px !important;
			}
			p+p{
				padding-top:2px;
			}
		}
    }
} 

			
.formContact{
	background-color: #e2e1df;
	margin-top: unset !important;
}

.locationKontakt{
	@include MontserratFont;
	font-weight: 300;
	
	.nazwa{
		font-size:16px;
		font-weight: 400;
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
	.opis {
		p{
		font-size:13px;
		}			
		.icon{
			width:50px;

			img{
				width:50px;
			}
		}
	}
	.link_do_strony{
		text-align: center;
		a{
			text-transform: uppercase;
			
		}
	}
	.odleglosci {    
		padding: 10px 0;
	}
}