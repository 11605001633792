#navbarNavDropdown{
    justify-content: center;
}
.navbar{
    background-color:transparent;    
    transition:.2s;
    padding:0;
}

.navbar-brand{
    &__logo{
        width:100px;
        transition:.2s;

        @include media(small){
            width:110px;
        }

        @include media(medium){
            width:110px;
        }

        @include media(large){
            width:120px;
        }

        @include media(xlarge){
            width:130px;
        }

        @include media(xxlarge){
            width:140px;
        }

    }
}
/* dla menu przyczepionego */
.header--fixed .navbar-collapse{
    .nav-item{
        .nav-link{
		  	color: #fff;
            font-size:12px;
				font-weight: 400;
				&:hover{
					text-decoration: underline;
				}
		  }	
		&--book{
			.nav-link {
			 	margin: -2px 10px -6px;
			}
		}
		@include media(large){
		 	padding:12px 0!important;
		}
	 }
}
/* koniec dla menu przyczepionego */

.navbar-collapse{
	    padding:10px 0;

	    @include media(large){
	        padding:0;
	    }

    .nav-item{
		&:nth-child(n) {
			margin-right: 20px;
            @include media(large){
                margin-right: 2px;
            }

            @include media(xlarge){
                margin-right: 10px;
            }
		}


        &.dropdown{   
                > a{
                    padding-right: 27px !important;
                    background:url('../images/downArrow.png') no-repeat top 50% right 10px;
                }     
        }
			

        .nav-link{
            @include headReservationFont;
            color:#666666;
            text-transform: uppercase; 
            margin:2px 5px;
            font-size:14px;
			font-weight: 600;
            letter-spacing: .3px;
            display:flex;
            align-items: center;
            padding: 10px 5px 5px 0;

            @include media(large){
                font-size:10px;
                padding:5px 0px 5px 0 !important;
            }

            @include media(xlarge){
                font-size:12px;
                margin:0 3px;
            }

            @include media(xxlarge){
                margin:0 5px;
                padding:9px nn5px 5px 0 !important;
                font-size:14px;
            }
				
            &:hover{
                text-decoration: underline;
            }
            

        }

       

        &.active{
           & > .hvr-border-fade{
                /* box-shadow: inset 0 0 0 1px #fff, 0 0 1px rgba(0, 0, 0, 0); */
                text-decoration: underline;
            }
        }

        &--book{
            opacity:.93;
            transition:.18s;
            margin-bottom:5px;

            &:hover{
                opacity:1;
            }

            .nav-link{
                background-color:$darkGold;
                padding:10px 15px 5px !important;
                margin: 3px 10px 0px 10px;
					  &::before {			
							content: '';
							position: absolute;
							border: #fff solid 1px;
							top: -6px;
							right: -6px;
							bottom: -6px;
							left: -6px;
					  }



                @include media(large){
                    margin: -4px 10px;
                    padding: 10px 15px !important;
                }
                
            }
        }

    }
    
    .dropdown{
        

        &:hover{
            @media(min-width:992px){
                .dropdown-menu{
                    display: block !important;   
                }
            }
            
        }

        .dropdown-menu{
            background-color:transparent;  
            border:0 !important;  
            
            min-width: 400px;
            
            //max
            @media(max-width:992px){
                padding-top: 0;
                padding-left: 7px;
            }

            @include media(large){
                margin-top:-5px; 
                display:none;  
                background-color: rgba(152, 142, 111, .8);
            }
    


            .nav-link{
                border-bottom:1px solid #fff !important;                
                transition:.2s;

                @include media(large){
                    padding:11px 7px 9px 7px !important;   
                }

                &.hvr-border-fade{
                    border: 0 !important;
                    border-bottom:1px solid #fff !important;   

                    &:hover{
                        border: 0 !important;
                        border-bottom:1px solid $galeriaGold !important;   
                        box-shadow: none !important;
                    }
                }
            }
        }
    }

}
.lang-item {
	margin-top: 5px;
}




