.ApartamentyLista {
    background-color: #E8E8E8;
    margin-top: 0;
    padding-top: 3rem;
    &__titleSection{
		margin-top: .6rem;
        margin-bottom:4rem;
		color: $headers;
		text-transform: none;
		@include HeadFont48;
		font-weight: 700;
        @media(min-width:576px){
            padding-bottom:0px;
        } 
        text-align: center;
    }	 
	&__subtitleSection{
		@include SubtitleFont14;
	}
	&__the_content{
        background: #fff;
        margin: 0 0 2rem 0;
        @media(min-width:992px){
            padding: 2rem 7rem;
        }
        p{
            margin: 1rem 0;
            @include MontserratFont; 
            font-size: 16px;
            font-weight: 200;
        }
    }
	 
   &__item{
        height:436px;
        width: 100%;
        display: flex;
        padding: 0;
        margin-bottom: 2rem;
        &__picture {
            padding: 0;
            max-height: 436px;
            .psuedo-background-img {
                position: relative;
                -o-object-fit: cover;
                object-fit: cover;
            }
        }
        &__textBoxBG {
            display: flex;
            margin-top: 3rem;
            margin-bottom: 3rem;
            align-items: center;
            &__titleOffer{          
                color: $headers;
                text-transform: uppercase;
                @include MontserratFont;
                font-size: 24px;
                font-weight: 400;
                @media(min-width:576px){
                    padding-bottom:0px;
                } 
                text-align: left;
            }
        }
        &__textBox {
            display: block;
            background: #fff;
            padding: 1.7rem 2rem 2rem 2rem;
            @media(min-width:992px){
                padding: 1.7rem 2rem 2rem 6rem;
            }
            &__opis {
                @include MontserratFont; 
                @include BodyFont;
                font-weight: 200;
                height: 6rem;
            }
        }

        &__ikona {
            width: 27px;
            height: 38px;
            padding: 0;
            margin-right: .5rem;
        }

        &__LinkButton{
            a{
                width: 50px;
                height: 50px;
                display: block;
                &.rezerwuj{
                    display: block;
                    width: 126px;
                    height: 50px;
                    padding: 14px 0 7px 0;
                    background: #5b5cd8;
                    border: 1px solid #5b5cd8;
                    font-size: 14px;
                    color: #fff;
                    text-transform: uppercase;
                    text-align: center;
                    letter-spacing: 3px;
                    margin-right: .4rem;
                    @include StandardHover;
                }
                &.linkArrow{
                    @include ArrowHover;
                }
            }
            margin: 3rem 0 0 0;
            display: flex;
        }

    }


}
