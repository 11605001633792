.PageStandard{
    
    background-color: #E8E8E8;
    margin-top: 0;
    padding-top: 3rem;
    &__titleSection{
		margin-top: .6rem;
        margin-bottom:3rem;
		color: $headers;
		text-transform: none;
		@include HeadFont48;
		font-weight: 700;
        @media(min-width:576px){
            padding-bottom:0px;
        } 
        text-align: center;
    }	 
	&__subtitleSection{
		@include SubtitleFont14;
	}
	 &__Container{
		background: #fff;
        @include MontserratFont;
        font-size: 14px;
        font-weight: 300;
        margin: 1rem auto 2rem;
        @media(min-width:992px){
            padding: 3rem 5rem;
        }
        ol, p{
            margin: 1rem 0;
        }
        h1, h2, h3, h4, h5, h6{
            margin: 2rem 0 1rem;
        }
        dl, ol, ul {
            margin: 2rem;
            line-height: 1.8rem;
        }
    }
}