.footer{
    margin-top:10px;
    background-color:$footerBackgroundColor;

    /* footerTop*/
    &__Top{ 
        background-color:$footerBackgroundColor;
        color:#fff;
        padding:25px 0;
		  img {
		  	width: 100%;	
	        @media(max-width:768px){
		  			width: 50%;	
	        } 
		  }	
		  .mainContainer {
            @media(max-width:576px){
                text-align: center!important;
            } 
				.logofooter {
					text-align: left;
	            @media(max-width:576px){
	                text-align: center!important;
	            } 
				}
			}	
		  a{
		  	color:#fff;
			text-dcoration: none;
			}
        p{
            font-size:13px;
            @media(min-width:576px){
                font-size:14px;
            } 
        }
         .dane p{
            font-size:18px;
				font-family: monarcha, serif;
				font-weight: 700;
				font-style: normal;
            @media(min-width:576px){
                font-size:14px;
            } 
			}
			.txt {
				color:$textColor;
				text-transform: uppercase;
				text-align: right;
				 @media(max-width:576px){
				     text-align: center!important;
				 } 
			}
			
			
        &__navFooter{

            margin: 0 20px;
            .nav-item{
                a,span{
                    color: white;
                    font-size:16px;
                    padding:1px 6px;

                    &:hover{
                        cursor:pointer;
                        text-decoration: none;
                    }
        
        
                    @media(max-width:576px){
                        font-size:14px;
                    }  

                }

                &.active{
                    a{
                        font-weight: bold;
                    }
                }
            }
            ul{
            @media(max-width:576px){
                list-style: none;
            }  

            }
        }
        &__column{
            &.socialmediaicons{
                margin-top: 4rem;
            }
            &__item:first-child{
                margin-top: 2rem
            }
			.SocialIcon {
                margin-top: 2rem;
				img {
					vertical-align: middle;
					width: 32x;
					@media(max-width:768px){
						width: 30px;
					} 
					text-align: right;
					@media(max-width:576px){
						text-align: center!important;
					} 
				}
				text-align: center;
				@media(min-width:576px){
					text-align: right;
				}
			}
            @media(max-width:576px){
                display: none!important;
            }  
        }
        
    }
    /* ./footerTop*/





     /*footer bottom*/
     &__copyBox{

        text-align: center;
        padding-top:15px;
        padding-bottom:15px;
        @media(max-width:576px){
            padding-bottom:55px;
        }  
        color:$textColor;
        background-color: #fff;
        @include FooterFont;
        p{
            font-size:10px;
            @media(min-width:576px){
                font-size:14px;
            }
        }
        
    }
    /* ./footer bottom*/

    /* footer newsletter */
    &__newsletter{
        width: 100%;
        color:$white;
        &__title{
            @include H3fontLatoBlack;
            color:$white;
            font-weight: 400;
            margin-bottom: 1rem;
            text-align: left;
        }
        .acceptance-936 {
            label {
                display: inline-block;
                margin-bottom: .5rem;
                margin-top: .5rem;
            }
            span {
                @include LatoFont;
                font-size: 9px;
                margin: 0;
            }
        }
        input[type=email]{
            border-radius:0 !important;
            border: 0;	
            border:1px solid #fff;
            height: auto;
            padding:5px;
            @include FooterFont;
            font-size: 11px;
            transition:.3s;
            margin-bottom:5px;
            text-transform: uppercase;	
            background: none;	
            color: #fff;
            @media(min-width:768px){
            }
    
            @media(min-width:992px){
            }
    
            &:focus{
                padding-left:13px;
                box-shadow: none !important;
            }
        }

        input[type=checkbox]{
            border: 1px solid #fff;
            background: transparent;
        }
        input[type=checkbox]{
            display: none; /* ukrywamy nasz input */
        }
        
        input[type=checkbox] + .wpcf7-list-item-label {
            position: relative;
            padding-left: 23px; /* margines wewnętrzny - trzeba zrobić trochę miejsca dla naszego pola */
        }
        
        input[type=checkbox] + .wpcf7-list-item-label:before {
            position: absolute;
            content: "";
            display: block; /* koniecznie element blokowy, aby móc nadać odpowiednie wymiary */
            width: 13px;
            height: 13px;
            top: 0; /* ustalamy pozycje */
            left: 0;
            cursor: pointer; /* dodamy jeszcze odpowiedni kursor */
            transition: all .4s ease;
            -webkit-transition: all .4s ease;
            border: 1px solid #ffffff;
            background-color: transparent;
        }
        
        /* checkbox */
        input[type=checkbox] + .wpcf7-list-item-label:before {
            border-radius: 0px;
        }
                
        /* gdy zaznaczony */
        input[type=checkbox]:checked + .wpcf7-list-item-label:before{
            background-color: #ffffff;
        }
        
        /* gdy zablokowany */
        input[type=checkbox]:disabled + .wpcf7-list-item-label:before{
        }
        
        /* gdy zaznaczony i zablokowany */
        input[type=checkbox]:checked:disabled + .wpcf7-list-item-label:before {
        }
    }
}
#wpcf7-f185-o1 .wpcf7-submit {
	width: 120px;
	height: 40px;
    background: #fff;
    border: 1px solid #fff;
    &:hover{
        background: transparent;
        color: #fff
    }
}

.footer__newsletter .wpcf7 .btn {    
	font-size: .8rem;
	line-height: 1.5;
	text-align: center;
	background-color: #fff;
	border-radius: 0;
	color: #5b5cd8 !important;
	font-family: Montserrat,sans-serif;
	text-transform: uppercase;
	margin: 5px auto !important;
	width: 200px;
	height: 35px;
	padding: 7px 0 5px;
	letter-spacing: 3px;
	font-weight: 400;
	border: 1px solid #fff;
    &:hover{
        border: 1px solid #fff;
        background-color: #5b5cd8;
        color: #fff !important;

    }
}