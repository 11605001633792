.SingleSpecialOffer {
    background-color: #E8E8E8;
    margin-top: 0;
    padding-top: 3rem;
    &__titleSection{
		margin-top: .6rem;
        margin-bottom:3rem;
		color: $headers;
		text-transform: none;
		@include HeadFont48;
		font-weight: 700;
        @media(min-width:576px){
            padding-bottom:0px;
        } 
        text-align: center;
    }	 
	&__subtitleSection{
		@include SubtitleFont14;
	}
	 &__Container{
		background: #fff;
        padding-top: 3rem;
        padding-bottom: 3rem;
		&__Opis{
			@include MontserratFont;
			padding: 0 6rem;
			font-size: 14px;
			font-weight: 300;
            margin: 1rem auto 2rem;
            &__krotki{
                margin: 10px auto;
            }
		}
        svg{
            height: 50px;
            width: auto;
            margin: 0 10px;
        }
    }
}