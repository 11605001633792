.newsletterrrr{

    div.wpcf7 .ajax-loader{
        position: absolute;
        margin-top: 8px;
    }

    .wpcf7-form-control-wrap{
        position:static;
        width:100%;

        span.wpcf7-not-valid-tip{
            width:100%;
            font-size:12px;

            @media(min-width:576px){
                //position: absolute;
            }
        }
    }

    .wpcf7-response-output.wpcf7-display-none.wpcf7-validation-errors,
    div.wpcf7-mail-sent-ng, div.wpcf7-aborted{
        border:none;
        color: #fff;
        font-size: 13px;
        text-align: center;
        margin-top: 12px;
        margin-bottom: 0px;
    }

    div.wpcf7-mail-sent-ok {
        border: none !important;
        color: #398f14 !important;
        text-align: center !important;
        font-weight: bold !important;
        margin: 10px 0px !important;
    }

    .btnBox{
        input[type=checkbox]{
            opacity:0;
        }
    }


    .formWrap{
        @include flex-center;
        padding: 25px 35px;
        margin-top: 30px;

        @media (min-width:768px){
            padding: 20px;
        }

        @media (min-width:1400px){
            padding: 25px 35px;
        }

        h5{
            color:$white;
        }

        input[type=submit]{
		  	border: 1px solid #fff;
			background: none;
			padding: 10px;
			
	        &:hover{
	            box-shadow: none !important;
					color: $newsletterBackgroundColor !important;
					background: #fff;
					border: 1px solid $newsletterBackgroundColor !important;
					padding: 10px!important;
	        }
        }
        
        
    }

    .agree{
        margin-top:-11px;
        margin-bottom: 10px;
    }

}