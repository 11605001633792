
.Bialystok {
    @include SectionMargin;
	@include media(xsmall){   
		padding-bottom: 1rem;
	}
	&__parallax {
	  /* The image used */
	  background-image: url('../images/biaystok-4660146_1920.jpg');

	  /* Set a specific height */
	  min-height: 700px; 

	  /* Create the parallax scrolling effect */
	  background-attachment: cover;
	  -webkit-background-size: cover;
	  -moz-background-size: cover;
	  @media(min-width:576px){
		background-attachment: fixed;
		-webkit-background-size: fixed;
		-moz-background-size: fixed;
	  } 

	  background-position: center;
	  background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 4rem;
		
	}
	&__BGright{
    }
    
	&__Offer {		
		margin-bottom: 2rem;
        padding-right: 3rem;
        padding-left: 3rem;
        background-image: url('../images/px_fiolet.png');
        background-position: left;
        background-repeat: repeat-y;
        @include media(large){
            margin-bottom: 0;            
        }
    
        &__titleSection{
            margin-top: .6rem;
            margin-bottom:3rem;
            color: $headers;
            text-transform: capitalize;
            text-align: left;
            @include HeadFont48;
            font-weight: 700;
            @media(min-width:576px){
                padding-bottom:0px;
            } 
        }	 

        &__subtitleSection{
            @include SubtitleFont14;
			text-align: left;
        }
        
		&__Title H3{
			@include HeadFont48;
			font-weight: 700;
			color: $headers;
			padding-bottom: 2rem;
		}

		&__Text{
			p {
                @include BodyFont;
				font-weight: 300;
			}
			padding-bottom: 2rem;
		}

		&__Link{
			a {
				@include MontserratFont; 
				font-size: 14px;
				font-weight: 700;
				letter-spacing: 2px;
				text-transform: uppercase;
				display: flex;
				color: $fioletowy;
				img {
					padding-left: 10px;
				}
			}
		}
	}
	
}