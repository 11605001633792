$breakpoints: (
    xsmall: (min-width: 460px), 
    small: (min-width: 576px), 
    medium: (min-width: 768px), 
    large: (min-width: 992px),  
    xlarge: (min-width: 1200px), 
    xxlarge: (min-width: 1410px)
);

@mixin media($breakpoint) {
    $size: map-get($breakpoints, $breakpoint);
    @if($size) {
      @media #{$size} {
        @content;
      }
    } @else {
      @error '"#{$breakpoint}" - błąd, nie wiem co to';
    }
}  
 
@mixin RoomBG($bgcolor, $color) {
	color: $color;
	background-color: $bgcolor;
}   

//uzycie
// @include media(xsmall) {
//     font-size:18px;
// }

@mixin HeaderSliderFirst {
  font-family: 'Montserrat', sans-serif;
}
@mixin HeaderSliderSecond {
  font-family: 'Montserrat', sans-serif;
}

@mixin headReservationFont {
    /* font-family:  Lato,sans-serif; */
  font-family: 'Montserrat', sans-serif;
}
@mixin formFont {
  /* font-family:  Lato,sans-serif; */
font-family: 'Montserrat', sans-serif;
}
@mixin ButtonFont {
  /* font-family:  Lato,sans-serif; */
font-family: 'Montserrat', sans-serif;
}

@mixin subTitleFontLight {
  /* font-family:  Lato,sans-serif; */
font-family: 'Montserrat', sans-serif;
}


@mixin headSpecialOffer {
  /* font-family:  Lato,sans-serif; */
font-family: 'Montserrat', sans-serif;
}

@mixin headFontLight {
  /* font-family:  Lato,sans-serif; */
font-family: 'Montserrat', sans-serif;
}

@mixin headFontMid {
  /* font-family:  Lato,sans-serif; */
font-family: 'Montserrat', sans-serif;
	 font-weight: 500;
}

@mixin titleFontBold {
  /* font-family:  Lato,sans-serif; */
font-family: 'Montserrat', sans-serif;
	font-weight: 700;
}

@mixin headFontBold {
  /* font-family:  Lato-Black,sans-serif; */
font-family: 'Montserrat', sans-serif;
	font-weight: normal;
  font-style: normal;
  color: $calkiemszary;
	font-size: 14px;
	@media(min-width:576px){
		font-size: 34px;
	}
	@media(min-width:768px){
		font-size: 38px;
	}
	@media(min-width:992px){
		font-size: 52px;
	}
}
@mixin headFontSemiBold {
  	/* font-family:  Lato,sans-serif; */
font-family: 'Montserrat', sans-serif;
	font-weight: 500;
	font-style: normal;
}

@mixin on-event($self: false) {
    @if $self {
      &,
      &:hover,
      &:active,
      &:focus {
        @content;
      }
    } @else {
      &:hover,
      &:active,
      &:focus {
        @content;
      }
    }
}

@mixin overlay($bgColor: #000, $bgOpacity: 1){
  .overlay{
      background:rgba($bgColor, $bgOpacity);
      position:absolute;
      width:100%;
      height:100%;
      left:0;
      top:0;
  }
}

@mixin flex-center{
  display:flex;
  align-items: center;
  justify-content: center;
}

@mixin subtitleSection {
	color: $specjaloffersTxt;
	padding-bottom: 45px;
	line-height: 35px;		
	font-size: 24px;
	@media(min-width:576px){
		font-size: 24px;
	}
	@media(min-width:768px){
		font-size: 26px;
	}
	@media(min-width:992px){
		font-size: 28px;
	}
}


@mixin H3fontLatoBlack {
  /* font-family:  Lato-Black,sans-serif; */
  font-family: 'Montserrat', sans-serif;
	font-weight: normal;
  font-style: normal;
  color: #ffffff;
	font-size: 14px;
	@media(min-width:576px){
		font-size: 14px;
	}
	@media(min-width:768px){
		font-size: 14px;
	}
	@media(min-width:992px){
		font-size: 14px;
	}
}

@mixin HeadFont48 {
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  text-transform: none;
  font-weight: 700;
  margin-bottom:3rem;
  color: $headers;
  a{
    color: $headers;
  }
  
  @include media(small){
    font-size: 38px;
  }

  @include media(medium){
    font-size: 44px;
  }

  @include media(large){
    font-size: 48px;
  }
}
@mixin HeadFont36 {
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
}
@mixin SubtitleFont14 {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-align: center;
  letter-spacing: 2px;
  line-height: 24px;
  color: #5C5D60;
  text-transform: uppercase;
  
  @include media(small){
    font-size: 14px;
  }

  @include media(medium){
    font-size: 16px;
  }

  @include media(large){
    font-size: 16px;
  }
}
@mixin FooterFont {
  font-family: 'Montserrat', sans-serif;
	font-weight: 400;
}
@mixin MontserratFont {
  font-family: 'Montserrat', sans-serif;
}
@mixin LatoFont {
  /* font-family: 'Lato',sans-serif; */
  font-family: 'Montserrat', sans-serif;
	font-weight: 400;
}
@mixin LatoFontRegular {
  /* font-family: 'Lato',sans-serif; */
  font-family: 'Montserrat', sans-serif;
}
@mixin RobotoRegular {
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Montserrat', sans-serif;
}
@mixin RobotoMedium {
  /* font-family: 'Roboto-Medium', sans-serif; */
  font-family: 'Montserrat', sans-serif;
}

@mixin StandardHover {
  &:hover{
    background: #fff;
    border: 1px solid #5b5cd8;
    color: #5b5cd8!important;
    text-decoration: none;
  }
}
@mixin ArrowHover {
  &:hover{
    opacity: .5;
  }
}
@mixin SectionMargin {
  margin-top: 4rem;
}


@mixin BodyFont {  
	font-size: 16px;
}