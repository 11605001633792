$yellow: #E35205;
$ultraLightGrey:#eaeaea;
$lightGray: #e2e1e0;
$darkGray: #6f7070;
$blue:#28487e;
$turquoise:#0b7284;
$turquoiseLight:#41b8bb;
$lightBlue:#988e6f;
$darkGold:#988e6f;
$galeriaGold: #988d6e;
$apartamentyGold: #988d6e;
$heavenly: #9BCBEB;
$orange: #E35205;
$darkGoldOpinions: #887846;
$white: #fff;
$specjaloffersTxt: #666666;
$newsletterBackgroundColor: #156f93;
$locationBackgroundColor: #eeeeee;
$udogodnieniaBackgroundColor: #988e6f;
$specjaloffersBackgroundColor: #e2e1e0;
$opinionBoxBackgroundColor: #f4f7fe;
$galeriaBoxBackgroundColor: #e2e1e0;
$welcomeBackgroundColor: #f4f7fe;
$roomsOne: #312613;
$roomsTwo: #6b8e4c;
$roomsThree: #e9a126;
$roomsFour: #4f505f;

/* by RP */
$mietowy: #6ED2BA;
$fioletowy: #5B5CD8;
$calkiemszary: #666666;
$footerBackgroundColor: #5B5CD8;
$textColor: #000000;
$headers: #5C5D60;
$APorange: #F2A50A;
$APred: #C61A59;
$APblue: #0C41AA;
$APgreen: #0D8473