.Udogodnienia{
    @include SectionMargin;
	background: #fff;
    overflow:hidden;
    &__titleSection{
		@include HeadFont48;
        @media(min-width:576px){
            padding-bottom:0px;
        } 
        text-align: center;
    }	
	&__icons {
		text-align: center;
		img {
			height: 30px;
        	@media(min-width:576px){
				height: 70px;
        	} 
			width: auto;
		}
		&__txt {
			color: $calkiemszary;
			@include LatoFontRegular;
			font-size: 14px;
			padding-top: 1rem;
			padding-bottom: 2rem;  
			@include media(xsmall){   
                @include BodyFont;
				padding-top: 2rem;
			}
		}
	}
	&__BgColors {
		margin: 3rem 0 0;
	}
	&__BgOrange{
		background: $APorange 0% 0% no-repeat padding-box;
		height: 9px;
	}
	&__BgRed{
		background: $APred 0% 0% no-repeat padding-box;
		height: 9px;
	}
	&__BgBlue{
		background: $APblue 0% 0% no-repeat padding-box;
		height: 9px;
	}
	&__BgGreen{
		background: $APgreen 0% 0% no-repeat padding-box;
		height: 9px;
	}
}