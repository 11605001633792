.SpecialOffers{
    overflow:hidden;
	padding-bottom:50px;
	margin: 0 auto;
    @include SectionMargin;
    &__titleSection{
		margin-top: .6rem;
        margin-bottom:3rem;
		color: $headers;
		text-transform: capitalize;
		text-align: center;
		@include HeadFont48;
		font-weight: 700;
        @media(min-width:576px){
            padding-bottom:0px;
        } 
    }	 
	&__subtitleSection{
		@include SubtitleFont14;
	}
	&__Offer {		
			padding-right: 4rem;
			padding-left: 4rem;

		&__Title H3{
			@include HeadFont48;
			font-weight: 700;
			color: $headers;
			padding-bottom: 0rem;
			margin-top: 1rem;
			@include media(xsmall){   
				padding-bottom: 2rem;
				margin-top: 1rem;
			}
		}

		&__Text{
			p {
				@include BodyFont;
				font-weight: 300;
			}
			padding-bottom: 2rem;
		}

		&__Link{
			margin-bottom: 2rem;
			@include media(xsmall){   
				margin-bottom: 1rem;
			}
			a {
				@include MontserratFont; 
				font-size: 14px;
				font-weight: 700;
				letter-spacing: 2px;
				text-transform: uppercase;
				display: flex;
				color: $fioletowy;
				img {
					padding-left: 10px;
				}
			}

		}
	}
	.SpecialOfferSlider {
		border: 9px solid #F1F3F5;
		border-right: 9px solid $fioletowy;
	 }

}




.SpecialOffersPage{
    overflow:hidden;
	padding-bottom:50px;
	margin: 0 auto;
	background-color: #e8e8e8;
	padding-top: 3rem;

    &__titleSection{
		margin-top: .6rem;
        margin-bottom:3rem;
		color: $headers;
		text-transform: capitalize;
		text-align: center;
		@include HeadFont48;
		font-weight: 700;
        @media(min-width:576px){
            padding-bottom:0px;
        } 
    }	 
	&__subtitleSection{
		@include SubtitleFont14;
	}


	&__Offer {		
		padding-right: 4rem;
		padding-left: 4rem;

		&__Title H3{
			@include HeadFont48;
			font-weight: 700;
			color: $headers;
			padding-bottom: 0rem;
			margin-top: 1rem;
			@include media(xsmall){   
				padding-bottom: 2rem;
				margin-top: 1rem;
			}
		}

		&__Text{
			p {
				font-size: 14px;
				font-weight: 300;
			}
			padding-bottom: 2rem;
		}

		&__Link{
			a {
				@include MontserratFont; 
				font-size: 14px;
				font-weight: 700;
				letter-spacing: 2px;
				text-transform: uppercase;
				display: flex;
				color: $fioletowy;
				img {
					padding-left: 10px;
				}
			}

		}
	}
	
        .item{
			  .textWrap{
					padding-right: 50px; 
					padding-left: 50px;	
                    @media(max-width:992px){
								padding-right: 0; 
								padding-left: 0;	
                    }
					.elementWrap {
						padding: 0 70px;
					}
				}	
			  background-color: #fff;	
            //item odd (nieparzysty)
                &:nth-of-type(odd) {

                    .photoWrap{
						  padding-left:0;	
                        @media(min-width:992px){
                            order:2;
                        }
                    @media(max-width:768px){
						  		padding-left: 15px;
                    }
                    }

                    .textWrap {
					 			margin: 20px 0;
                        @media(min-width:992px){
                            order:1;
									margin: 0;
                        }
                    }
                }

            

            //item even (parzysty)
            &:nth-of-type(even) {
                .photoWrap{
						  padding-right: 0;	
                    @media(min-width:992px){
                    		order:1;
                    }
                    @media(max-width:768px){
						  		padding-right: 15px;
                    }
                }

                .textWrap {
					 		margin: 20px 0;
                    @media(min-width:992px){
                        order:2;
								margin: 0;
                    }
						  
                }                
            	}         

            // end item even (parzysty)
        }


}