@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
html {
  box-sizing: border-box;
  font-size: 16px;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  /* list-style: none; */
}

img {
  max-width: 100%;
  height: auto;
}

/* by RP */
body {
  transition: .1s;
}

body.fadeIn {
  opacity: 1;
}

.mainContainer {
  max-width: 1410px;
}

@media (min-width: 992px) and (max-width: 1199px) {
  .mainContainer {
    padding-left: 25px;
    padding-right: 25px;
  }
}

@media (min-width: 1200px) and (max-width: 1500px) {
  .mainContainer {
    padding-left: 50px;
    padding-right: 50px;
  }
}

.bold {
  /* font-family:  Lato-Black,sans-serif; */
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #666666;
  font-size: 14px;
}

@media (min-width: 576px) {
  .bold {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .bold {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .bold {
    font-size: 52px;
  }
}

a {
  color: #000;
}

/* BACKGROUND */
.darkBlueBg {
  background-color: #eaeaea;
}

.blueBg {
  background-color: #28487e;
}

.lightBlueBg {
  background-color: #988e6f;
}

.heavenlyBg {
  background-color: #9BCBEB;
}

.orangeBg {
  background-color: #E35205;
}

.lightGrayBg {
  background-color: #e2e1e0;
}

.whiteBg {
  background-color: #fff;
}

.roomBg {
  background-color: #fafafa;
}

/* COLORS */
.orangeColor {
  color: #E35205;
}

.darkBlueColor {
  color: #eaeaea;
}

.whiteColor {
  color: #fff;
}

p {
  font-size: 14px;
}

@media (min-width: 768px) {
  p {
    font-size: 15px;
  }
}

@media (min-width: 1200px) {
  p {
    font-size: 16px;
  }
}

.slide {
  width: 100%;
  height: 100%;
  position: relative;
}

.psuedo-background-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.subtitleSection {
  color: #000000;
  font-size: 28px;
  text-align: center;
  padding-bottom: 15px;
  text-transform: lowercase;
  /* font-family:  Lato,sans-serif; */
  font-family: 'Montserrat', sans-serif;
}

@media (min-width: 460px) {
  .subtitleSection {
    font-size: 18px;
  }
}

@media (min-width: 576px) {
  .subtitleSection {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .subtitleSection {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .subtitleSection {
    font-size: 24px;
  }
}

@media (min-width: 1410px) {
  .subtitleSection {
    font-size: 28px;
  }
}

.subtitleSection strong, .subtitleSection--bold {
  /* font-family:  Lato-Black,sans-serif; */
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #666666;
  font-size: 14px;
}

@media (min-width: 576px) {
  .subtitleSection strong, .subtitleSection--bold {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .subtitleSection strong, .subtitleSection--bold {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .subtitleSection strong, .subtitleSection--bold {
    font-size: 52px;
  }
}

.subtitleSection--small {
  font-size: 26px;
}

@media (min-width: 460px) {
  .subtitleSection--small {
    font-size: 28px;
  }
}

@media (min-width: 576px) {
  .subtitleSection--small {
    font-size: 30px;
  }
}

@media (min-width: 768px) {
  .subtitleSection--small {
    font-size: 32px;
  }
}

@media (min-width: 992px) {
  .subtitleSection--small {
    font-size: 34px;
  }
}

@media (min-width: 1410px) {
  .subtitleSection--small {
    font-size: 36px;
  }
}

.subtitleSection--xsmall {
  font-size: 16px;
}

@media (min-width: 460px) {
  .subtitleSection--xsmall {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .subtitleSection--xsmall {
    font-size: 18px;
  }
}

.titleSection {
  color: #eaeaea;
  font-size: 52px;
  text-align: center;
  padding-bottom: 25px;
  text-transform: capitalize;
  /* font-family:  Lato-Black,sans-serif; */
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #666666;
  font-size: 14px;
}

@media (min-width: 576px) {
  .titleSection {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .titleSection {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .titleSection {
    font-size: 52px;
  }
}

@media (min-width: 460px) {
  .titleSection {
    font-size: 33px;
  }
}

@media (min-width: 576px) {
  .titleSection {
    font-size: 36px;
  }
}

@media (min-width: 768px) {
  .titleSection {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .titleSection {
    font-size: 40px;
  }
}

@media (min-width: 1410px) {
  .titleSection {
    font-size: 52px;
  }
}

.titleSection strong, .titleSection--bold {
  /* font-family:  Lato-Black,sans-serif; */
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #666666;
  font-size: 14px;
}

@media (min-width: 576px) {
  .titleSection strong, .titleSection--bold {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .titleSection strong, .titleSection--bold {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .titleSection strong, .titleSection--bold {
    font-size: 52px;
  }
}

.titleSection--small {
  font-size: 26px;
}

@media (min-width: 460px) {
  .titleSection--small {
    font-size: 28px;
  }
}

@media (min-width: 576px) {
  .titleSection--small {
    font-size: 30px;
  }
}

@media (min-width: 768px) {
  .titleSection--small {
    font-size: 32px;
  }
}

@media (min-width: 992px) {
  .titleSection--small {
    font-size: 34px;
  }
}

@media (min-width: 1410px) {
  .titleSection--small {
    font-size: 36px;
  }
}

.titleSection--xsmall {
  font-size: 20px;
}

@media (min-width: 460px) {
  .titleSection--xsmall {
    font-size: 22px;
  }
}

@media (min-width: 768px) {
  .titleSection--xsmall {
    font-size: 24px;
  }
}

.owl-item.active.center {
  transform: scale(1.1, 1.2);
}

.owl-carousel .owl-nav.disabled {
  display: none !important;
}

/* .container-fluid {width: 90%!important;} */
body {
  font-family: 'Montserrat', sans-serif;
}

.yellowBigBtn,
.blueBigBtn {
  border-radius: 0;
  padding: 8px;
  font-size: 12px;
  width: 100%;
  max-width: 140px;
  margin-bottom: 2px;
  margin-top: 2px;
  text-transform: uppercase;
  display: none;
}

@media (min-width: 350px) {
  .yellowBigBtn,
  .blueBigBtn {
    font-size: 13px;
  }
}

@media (min-width: 576px) {
  .yellowBigBtn,
  .blueBigBtn {
    font-size: 14px;
    max-width: 150px;
  }
}

@media (min-width: 768px) {
  .yellowBigBtn,
  .blueBigBtn {
    display: inline-block;
  }
}

@media (min-width: 992px) {
  .yellowBigBtn,
  .blueBigBtn {
    padding: 10px 9px;
    font-size: 16px;
    max-width: 240px;
  }
}

.yellowBigBtn:hover,
.blueBigBtn:hover {
  opacity: .9;
}

.yellowBigBtn {
  background-color: #5b5cd8;
  color: #fff !important;
}

.yellowBigBtn:focus, .yellowBigBtn:active {
  background-color: #5b5cd8 !important;
  color: #fff !important;
}

.yellowBigBtn.specialReserveBtn {
  font-weight: 600;
  padding: 7px 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  display: block !important;
  letter-spacing: .3px;
}

@media (min-width: 768px) {
  .yellowBigBtn.specialReserveBtn {
    padding: 10px 7px;
    max-width: 205px;
  }
}

.blueBigBtn {
  background-color: #28487e;
  color: #fff;
}

.blueBigBtn:hover {
  color: #fff;
}

.blueBigBtn:focus, .blueBigBtn:active {
  background-color: #28487e !important;
}

.yellowSmallBtn {
  background-color: #5b5cd8;
  text-transform: uppercase;
  width: 100%;
  max-width: 140px;
  font-size: 14px;
  color: #fff !important;
  border: 0;
}

.yellowSmallBtn:hover {
  border: 0;
  cursor: pointer;
  color: #fff !important;
}

@media (min-width: 576px) {
  .yellowSmallBtn {
    font-size: 14px;
    max-width: 160px;
  }
}

@media (min-width: 768px) {
  .yellowSmallBtn {
    padding: 9px 30px;
    font-size: 16px;
    max-width: 200px;
  }
}

@media (min-width: 992px) {
  .yellowSmallBtn {
    padding: 9px 30px;
    font-size: 16px;
  }
}

.blueSmallBtn {
  background-color: #eaeaea;
  text-transform: uppercase;
  width: 100%;
  max-width: 140px;
  font-size: 14px;
  color: #fff !important;
  border: 0;
  border-radius: 0;
  border-color: #eaeaea !important;
}

.blueSmallBtn:hover {
  color: #fff !important;
  border: none !important;
  cursor: pointer;
}

@media (min-width: 576px) {
  .blueSmallBtn {
    font-size: 14px;
    max-width: 150px;
  }
}

@media (min-width: 768px) {
  .blueSmallBtn {
    padding: 7px 5px;
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .blueSmallBtn {
    padding: 8px 5px;
    font-size: 14px;
  }
}

.sliderBtn {
  font-size: 11px;
  background-color: transparent;
  border: 1px solid #fff;
  margin: 20px auto 0 auto;
  color: #fff;
  font-weight: bold;
  padding: 4px 7px;
  position: relative;
  z-index: 10;
  transition: .3s;
}

.sliderBtn:active, .sliderBtn:hover {
  background-color: #28487e;
  color: #fff;
  text-decoration: none;
}

@media (min-width: 768px) {
  .sliderBtn {
    font-size: 13px;
    padding: 5px 10px;
  }
}

.mapBtn {
  background-color: #fff;
  color: #1d3649;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0;
  padding: 6px 31px;
  text-transform: uppercase;
}

.hvr-ripple-out.yellow:before {
  border: #5b5cd8 solid 6px;
}

.hvr-ripple-out.blue:before {
  border: #eaeaea solid 6px;
}

.hvr-ripple-out.white:before {
  border: #fff solid 6px;
}

.orangeBtn {
  background-color: #E35205 !important;
  color: #fff !important;
}

.orangeBtn:hover, .orangeBtn:active, .orangeBtn:focus {
  background-color: #E35205 !important;
}

.hvr-ripple-out.orange:before {
  border: #E35205 solid 6px;
}

.header {
  width: 100% !important;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 30;
}

@media (min-width: 992px) {
  .header {
    background-color: #fff;
  }
}

.header__calltoUs {
  background: #5B5CD8 0% 0% no-repeat padding-box;
  opacity: 1;
  display: none;
  width: 120px;
  height: 82px;
  margin-left: auto;
}

@media (min-width: 460px) {
  .header__calltoUs {
    display: block;
  }
}

@media (min-width: 992px) {
  .header__calltoUs {
    width: 159px;
    height: 82px;
  }
}

@media (min-width: 1200px) {
  .header__calltoUs {
    width: 159px;
    height: 82px;
  }
}

.header__calltoUs a {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
}

.header__calltoUs a p {
  color: #ffffff;
  text-align: left;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  font-size: 10px;
  padding: 0 0 0 10px;
}

@media (min-width: 992px) {
  .header__calltoUs a p {
    font-size: 14px;
  }
}

.header__calltoUs a svg {
  color: #fff;
}

.header__calltoUs:hover {
  text-decoration: none;
}

.header__calltoUs svg {
  max-width: 12px;
  height: auto;
}

.header__language {
  display: flex;
  margin: 0 0 0 15px;
  width: 15px;
  display: none;
}

.header__languageLink:nth-child(2) {
  display: none;
}

.header .navbar-brand svg .a {
  fill: #6ed2ba;
}

.header .navbar-brand svg .b {
  fill: #5b5cd8;
}

.header .LogoTopHeaderFiolet {
  display: block;
  width: 200px;
  height: auto;
}

.header .LogoTopHeaderWhite {
  display: none;
}

.header--fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(91, 92, 216, 0.8);
}

.header--fixed .LogoTopHeaderFiolet {
  display: none;
}

.header--fixed .LogoTopHeaderWhite {
  display: block;
  width: 200px;
  height: auto;
}

.header--fixed .navbar-brand svg .a {
  fill: #6ed2ba;
}

.header--fixed .navbar-brand svg .b {
  fill: #fff;
}

.header--fixed .navbar {
  padding-top: 0;
  padding-bottom: 0;
}

.header--fixed .dropdown-menu {
  margin-top: 0 !important;
}

@media (min-width: 992px) {
  .header--fixed .dropdown-menu {
    background-color: rgba(152, 142, 111, 0.8) !important;
  }
}

.header--fixed .burger span {
  background-color: #fff;
}

.mainSlider {
  height: calc(100vh);
  min-height: 650px;
  padding-top: 0 !important;
}

.mainSlider .item {
  height: calc(100vh);
  min-height: 400px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: none;
}

@media (min-width: 576px) {
  .mainSlider .item {
    min-height: 500px;
  }
}

@media (min-width: 768px) {
  .mainSlider .item {
    min-height: 550px;
  }
}

@media (min-width: 992px) {
  .mainSlider .item {
    min-height: 550px;
  }
}

@media (min-width: 1200px) {
  .mainSlider .item {
    min-height: 550px;
  }
}

@media (min-width: 1400px) {
  .mainSlider .item {
    min-height: 600px;
  }
}

@media (min-width: 1600px) {
  .mainSlider .item {
    min-height: 700px;
  }
}

.mainSlider .item .overlay {
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.mainSlider .item .textBox {
  position: absolute;
  position: absolute;
  top: 42%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* 
            @media(min-width:992px){
                width: 75%;            
            } */
}

.mainSlider .item .textBox .mainText {
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 22px;
  font-weight: 900;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
  text-transform: inherit;
  letter-spacing: 8px;
}

@media (min-width: 576px) {
  .mainSlider .item .textBox .mainText {
    font-size: 36px;
  }
}

@media (min-width: 992px) {
  .mainSlider .item .textBox .mainText {
    font-size: 48px;
  }
}

@media (min-width: 1200px) {
  .mainSlider .item .textBox .mainText {
    font-size: 52px;
  }
}

.mainSlider .item .textBox .secondText {
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 26px;
  font-weight: 900;
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.3);
  padding-top: 5px;
  letter-spacing: 4px;
}

.mainSlider .item .textBox .secondText p {
  margin-top: 1rem;
  font-size: 16px;
}

@media (min-width: 576px) {
  .mainSlider .item .textBox .secondText p {
    font-size: 20px;
  }
}

@media (min-width: 992px) {
  .mainSlider .item .textBox .secondText p {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .mainSlider .item .textBox .secondText p {
    font-size: 26px;
  }
}

.mainSlider .item .textBox .btnBox {
  padding-top: 5px;
}

@media (min-width: 768px) {
  .mainSlider .item .textBox .btnBox {
    padding-top: 20px;
  }
}

.mainSlider .item.onMobile {
  display: block;
}

@media (min-width: 768px) {
  .mainSlider .item.onMobile {
    display: none;
  }
}

.mainSlider .item.onDesktop {
  display: none;
}

@media (min-width: 768px) {
  .mainSlider .item.onDesktop {
    display: block;
  }
}

.mainSlider .item.onMobile.onDesktop {
  display: block;
}

.mainSlider .owl-theme .owl-nav {
  width: 100%;
  max-width: 1630px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  height: 0;
  margin-top: -40px;
}

.mainSlider .owl-theme .owl-dots {
  position: absolute;
  bottom: 10px;
  text-align: center;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%);
}

.mainSlider .owl-theme .owl-dots .owl-dot span {
  margin: 5px 4px;
  background-color: #fff;
}

.mainSlider .owl-theme .owl-dots .owl-dot.active span, .mainSlider .owl-theme .owl-dots .owl-dot:hover span {
  background-color: #D6D6D6;
}

.mainSlider .owl-carousel .owl-nav button.owl-prev,
.mainSlider .owl-carousel .owl-nav button.owl-next {
  width: 30px;
  height: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 20px 5px;
}

@media (min-width: 576px) {
  .mainSlider .owl-carousel .owl-nav button.owl-prev,
  .mainSlider .owl-carousel .owl-nav button.owl-next {
    margin: 20px;
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 992px) {
  .mainSlider .owl-carousel .owl-nav button.owl-prev,
  .mainSlider .owl-carousel .owl-nav button.owl-next {
    width: 60px;
    height: 60px;
  }
}

.mainSlider .owl-carousel .owl-nav button.owl-prev:hover,
.mainSlider .owl-carousel .owl-nav button.owl-next:hover {
  background-color: transparent;
}

.mainSlider .owl-carousel .owl-nav button.owl-prev {
  background-image: url("../images/owl-left.png");
}

.mainSlider .owl-carousel .owl-nav button.owl-next {
  background-image: url("../images/owl-right.png");
}

section {
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  margin-top: 3rem;
}

.SpecialOffers {
  overflow: hidden;
  padding-bottom: 50px;
  margin: 0 auto;
  margin-top: 4rem;
}

.SpecialOffers__titleSection {
  margin-top: .6rem;
  margin-bottom: 3rem;
  color: #5C5D60;
  text-transform: capitalize;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  text-transform: none;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #5C5D60;
  font-weight: 700;
}

.SpecialOffers__titleSection a {
  color: #5C5D60;
}

@media (min-width: 576px) {
  .SpecialOffers__titleSection {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .SpecialOffers__titleSection {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .SpecialOffers__titleSection {
    font-size: 48px;
  }
}

@media (min-width: 576px) {
  .SpecialOffers__titleSection {
    padding-bottom: 0px;
  }
}

.SpecialOffers__subtitleSection {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-align: center;
  letter-spacing: 2px;
  line-height: 24px;
  color: #5C5D60;
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .SpecialOffers__subtitleSection {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .SpecialOffers__subtitleSection {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .SpecialOffers__subtitleSection {
    font-size: 16px;
  }
}

.SpecialOffers__Offer {
  padding-right: 4rem;
  padding-left: 4rem;
}

.SpecialOffers__Offer__Title H3 {
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  text-transform: none;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #5C5D60;
  font-weight: 700;
  color: #5C5D60;
  padding-bottom: 0rem;
  margin-top: 1rem;
}

.SpecialOffers__Offer__Title H3 a {
  color: #5C5D60;
}

@media (min-width: 576px) {
  .SpecialOffers__Offer__Title H3 {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .SpecialOffers__Offer__Title H3 {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .SpecialOffers__Offer__Title H3 {
    font-size: 48px;
  }
}

@media (min-width: 460px) {
  .SpecialOffers__Offer__Title H3 {
    padding-bottom: 2rem;
    margin-top: 1rem;
  }
}

.SpecialOffers__Offer__Text {
  padding-bottom: 2rem;
}

.SpecialOffers__Offer__Text p {
  font-size: 16px;
  font-weight: 300;
}

.SpecialOffers__Offer__Link {
  margin-bottom: 2rem;
}

@media (min-width: 460px) {
  .SpecialOffers__Offer__Link {
    margin-bottom: 1rem;
  }
}

.SpecialOffers__Offer__Link a {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: flex;
  color: #5B5CD8;
}

.SpecialOffers__Offer__Link a img {
  padding-left: 10px;
}

.SpecialOffers .SpecialOfferSlider {
  border: 9px solid #F1F3F5;
  border-right: 9px solid #5B5CD8;
}

.SpecialOffersPage {
  overflow: hidden;
  padding-bottom: 50px;
  margin: 0 auto;
  background-color: #e8e8e8;
  padding-top: 3rem;
}

.SpecialOffersPage__titleSection {
  margin-top: .6rem;
  margin-bottom: 3rem;
  color: #5C5D60;
  text-transform: capitalize;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  text-transform: none;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #5C5D60;
  font-weight: 700;
}

.SpecialOffersPage__titleSection a {
  color: #5C5D60;
}

@media (min-width: 576px) {
  .SpecialOffersPage__titleSection {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .SpecialOffersPage__titleSection {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .SpecialOffersPage__titleSection {
    font-size: 48px;
  }
}

@media (min-width: 576px) {
  .SpecialOffersPage__titleSection {
    padding-bottom: 0px;
  }
}

.SpecialOffersPage__subtitleSection {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-align: center;
  letter-spacing: 2px;
  line-height: 24px;
  color: #5C5D60;
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .SpecialOffersPage__subtitleSection {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .SpecialOffersPage__subtitleSection {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .SpecialOffersPage__subtitleSection {
    font-size: 16px;
  }
}

.SpecialOffersPage__Offer {
  padding-right: 4rem;
  padding-left: 4rem;
}

.SpecialOffersPage__Offer__Title H3 {
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  text-transform: none;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #5C5D60;
  font-weight: 700;
  color: #5C5D60;
  padding-bottom: 0rem;
  margin-top: 1rem;
}

.SpecialOffersPage__Offer__Title H3 a {
  color: #5C5D60;
}

@media (min-width: 576px) {
  .SpecialOffersPage__Offer__Title H3 {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .SpecialOffersPage__Offer__Title H3 {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .SpecialOffersPage__Offer__Title H3 {
    font-size: 48px;
  }
}

@media (min-width: 460px) {
  .SpecialOffersPage__Offer__Title H3 {
    padding-bottom: 2rem;
    margin-top: 1rem;
  }
}

.SpecialOffersPage__Offer__Text {
  padding-bottom: 2rem;
}

.SpecialOffersPage__Offer__Text p {
  font-size: 14px;
  font-weight: 300;
}

.SpecialOffersPage__Offer__Link a {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: flex;
  color: #5B5CD8;
}

.SpecialOffersPage__Offer__Link a img {
  padding-left: 10px;
}

.SpecialOffersPage .item {
  background-color: #fff;
}

.SpecialOffersPage .item .textWrap {
  padding-right: 50px;
  padding-left: 50px;
}

@media (max-width: 992px) {
  .SpecialOffersPage .item .textWrap {
    padding-right: 0;
    padding-left: 0;
  }
}

.SpecialOffersPage .item .textWrap .elementWrap {
  padding: 0 70px;
}

.SpecialOffersPage .item:nth-of-type(odd) .photoWrap {
  padding-left: 0;
}

@media (min-width: 992px) {
  .SpecialOffersPage .item:nth-of-type(odd) .photoWrap {
    order: 2;
  }
}

@media (max-width: 768px) {
  .SpecialOffersPage .item:nth-of-type(odd) .photoWrap {
    padding-left: 15px;
  }
}

.SpecialOffersPage .item:nth-of-type(odd) .textWrap {
  margin: 20px 0;
}

@media (min-width: 992px) {
  .SpecialOffersPage .item:nth-of-type(odd) .textWrap {
    order: 1;
    margin: 0;
  }
}

.SpecialOffersPage .item:nth-of-type(even) .photoWrap {
  padding-right: 0;
}

@media (min-width: 992px) {
  .SpecialOffersPage .item:nth-of-type(even) .photoWrap {
    order: 1;
  }
}

@media (max-width: 768px) {
  .SpecialOffersPage .item:nth-of-type(even) .photoWrap {
    padding-right: 15px;
  }
}

.SpecialOffersPage .item:nth-of-type(even) .textWrap {
  margin: 20px 0;
}

@media (min-width: 992px) {
  .SpecialOffersPage .item:nth-of-type(even) .textWrap {
    order: 2;
    margin: 0;
  }
}

.Location {
  padding-top: 0;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 4rem;
  text-align: center;
}

.Location__titleSection {
  margin-bottom: 3rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  text-transform: none;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #5C5D60;
}

.Location__titleSection a {
  color: #5C5D60;
}

@media (min-width: 576px) {
  .Location__titleSection {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .Location__titleSection {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .Location__titleSection {
    font-size: 48px;
  }
}

@media (min-width: 576px) {
  .Location__titleSection {
    padding-bottom: 0px;
  }
}

.Location__subtitleSection {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-align: center;
  letter-spacing: 2px;
  line-height: 24px;
  color: #5C5D60;
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .Location__subtitleSection {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .Location__subtitleSection {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .Location__subtitleSection {
    font-size: 16px;
  }
}

#map {
  width: 100%;
  height: 500px;
}

.footer {
  margin-top: 10px;
  background-color: #5B5CD8;
  /* footerTop*/
  /* ./footerTop*/
  /*footer bottom*/
  /* ./footer bottom*/
  /* footer newsletter */
}

.footer__Top {
  background-color: #5B5CD8;
  color: #fff;
  padding: 25px 0;
}

.footer__Top img {
  width: 100%;
}

@media (max-width: 768px) {
  .footer__Top img {
    width: 50%;
  }
}

@media (max-width: 576px) {
  .footer__Top .mainContainer {
    text-align: center !important;
  }
}

.footer__Top .mainContainer .logofooter {
  text-align: left;
}

@media (max-width: 576px) {
  .footer__Top .mainContainer .logofooter {
    text-align: center !important;
  }
}

.footer__Top a {
  color: #fff;
  text-dcoration: none;
}

.footer__Top p {
  font-size: 13px;
}

@media (min-width: 576px) {
  .footer__Top p {
    font-size: 14px;
  }
}

.footer__Top .dane p {
  font-size: 18px;
  font-family: monarcha, serif;
  font-weight: 700;
  font-style: normal;
}

@media (min-width: 576px) {
  .footer__Top .dane p {
    font-size: 14px;
  }
}

.footer__Top .txt {
  color: #000000;
  text-transform: uppercase;
  text-align: right;
}

@media (max-width: 576px) {
  .footer__Top .txt {
    text-align: center !important;
  }
}

.footer__Top__navFooter {
  margin: 0 20px;
}

.footer__Top__navFooter .nav-item a, .footer__Top__navFooter .nav-item span {
  color: white;
  font-size: 16px;
  padding: 1px 6px;
}

.footer__Top__navFooter .nav-item a:hover, .footer__Top__navFooter .nav-item span:hover {
  cursor: pointer;
  text-decoration: none;
}

@media (max-width: 576px) {
  .footer__Top__navFooter .nav-item a, .footer__Top__navFooter .nav-item span {
    font-size: 14px;
  }
}

.footer__Top__navFooter .nav-item.active a {
  font-weight: bold;
}

@media (max-width: 576px) {
  .footer__Top__navFooter ul {
    list-style: none;
  }
}

.footer__Top__column.socialmediaicons {
  margin-top: 4rem;
}

.footer__Top__column__item:first-child {
  margin-top: 2rem;
}

.footer__Top__column .SocialIcon {
  margin-top: 2rem;
  text-align: center;
}

.footer__Top__column .SocialIcon img {
  vertical-align: middle;
  width: 32x;
  text-align: right;
}

@media (max-width: 768px) {
  .footer__Top__column .SocialIcon img {
    width: 30px;
  }
}

@media (max-width: 576px) {
  .footer__Top__column .SocialIcon img {
    text-align: center !important;
  }
}

@media (min-width: 576px) {
  .footer__Top__column .SocialIcon {
    text-align: right;
  }
}

@media (max-width: 576px) {
  .footer__Top__column {
    display: none !important;
  }
}

.footer__copyBox {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  color: #000000;
  background-color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}

@media (max-width: 576px) {
  .footer__copyBox {
    padding-bottom: 55px;
  }
}

.footer__copyBox p {
  font-size: 10px;
}

@media (min-width: 576px) {
  .footer__copyBox p {
    font-size: 14px;
  }
}

.footer__newsletter {
  width: 100%;
  color: #fff;
  /* checkbox */
  /* gdy zaznaczony */
  /* gdy zablokowany */
  /* gdy zaznaczony i zablokowany */
}

.footer__newsletter__title {
  /* font-family:  Lato-Black,sans-serif; */
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #ffffff;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  margin-bottom: 1rem;
  text-align: left;
}

@media (min-width: 576px) {
  .footer__newsletter__title {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .footer__newsletter__title {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .footer__newsletter__title {
    font-size: 14px;
  }
}

.footer__newsletter .acceptance-936 label {
  display: inline-block;
  margin-bottom: .5rem;
  margin-top: .5rem;
}

.footer__newsletter .acceptance-936 span {
  /* font-family: 'Lato',sans-serif; */
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 9px;
  margin: 0;
}

.footer__newsletter input[type=email] {
  border-radius: 0 !important;
  border: 0;
  border: 1px solid #fff;
  height: auto;
  padding: 5px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 11px;
  transition: .3s;
  margin-bottom: 5px;
  text-transform: uppercase;
  background: none;
  color: #fff;
}

.footer__newsletter input[type=email]:focus {
  padding-left: 13px;
  box-shadow: none !important;
}

.footer__newsletter input[type=checkbox] {
  border: 1px solid #fff;
  background: transparent;
}

.footer__newsletter input[type=checkbox] {
  display: none;
  /* ukrywamy nasz input */
}

.footer__newsletter input[type=checkbox] + .wpcf7-list-item-label {
  position: relative;
  padding-left: 23px;
  /* margines wewnętrzny - trzeba zrobić trochę miejsca dla naszego pola */
}

.footer__newsletter input[type=checkbox] + .wpcf7-list-item-label:before {
  position: absolute;
  content: "";
  display: block;
  /* koniecznie element blokowy, aby móc nadać odpowiednie wymiary */
  width: 13px;
  height: 13px;
  top: 0;
  /* ustalamy pozycje */
  left: 0;
  cursor: pointer;
  /* dodamy jeszcze odpowiedni kursor */
  transition: all .4s ease;
  -webkit-transition: all .4s ease;
  border: 1px solid #ffffff;
  background-color: transparent;
}

.footer__newsletter input[type=checkbox] + .wpcf7-list-item-label:before {
  border-radius: 0px;
}

.footer__newsletter input[type=checkbox]:checked + .wpcf7-list-item-label:before {
  background-color: #ffffff;
}

#wpcf7-f185-o1 .wpcf7-submit {
  width: 120px;
  height: 40px;
  background: #fff;
  border: 1px solid #fff;
}

#wpcf7-f185-o1 .wpcf7-submit:hover {
  background: transparent;
  color: #fff;
}

.footer__newsletter .wpcf7 .btn {
  font-size: .8rem;
  line-height: 1.5;
  text-align: center;
  background-color: #fff;
  border-radius: 0;
  color: #5b5cd8 !important;
  font-family: Montserrat,sans-serif;
  text-transform: uppercase;
  margin: 5px auto !important;
  width: 200px;
  height: 35px;
  padding: 7px 0 5px;
  letter-spacing: 3px;
  font-weight: 400;
  border: 1px solid #fff;
}

.footer__newsletter .wpcf7 .btn:hover {
  border: 1px solid #fff;
  background-color: #5b5cd8;
  color: #fff !important;
}

.socialFixed {
  width: 30px;
  height: 250px;
  position: fixed;
  top: 35%;
  right: 0;
  display: none;
  z-index: 30;
}

@media (min-width: 576px) {
  .socialFixed {
    display: block;
  }
}

.socialFixed .item {
  margin-top: 5px;
  padding-top: 0;
}

.modal {
  color: #eaeaea;
}

.modal .modal-body {
  font-size: 13px;
}

@media (min-width: 576px) {
  .modal .modal-body {
    font-size: 15px;
  }
}

.modal .modal-body h5 {
  font-weight: bold;
  font-size: 16px;
}

@media (min-width: 576px) {
  .modal .modal-body h5 {
    font-size: 18px;
  }
}

.reservationPanelWrap.fixed {
  display: block;
}

.reservationPanelWrap.fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(110, 210, 186, 0.9);
  /* display: none; */
  z-index: 1;
}

.reservationPanelWrap.fixed .reservationPanel {
  margin-top: unset;
}

.reservationPanelWrap.fixed .reservationPanel .-form label {
  display: none !important;
}

.reservationPanelWrap.fixed .reservationPanel .-form button {
  background-color: #5b5cd8;
  margin: 5px 0 !important;
  padding: 0;
  width: 200px;
  height: 40px;
}

.reservationPanelWrap.fixed .reservationPanel .form-inline .form-group {
  margin: 0;
}

.reservationPanel {
  background-color: #6ED2BA;
  /* margin-bottom: 50px; */
  z-index: 20;
  position: relative;
  display: none;
  padding: 0;
  margin-top: -135px;
  max-width: 800px;
  max-width: 900px;
}

@media (min-width: 576px) {
  .reservationPanel {
    display: block;
  }
}

.reservationPanel .form-inline .form-group {
  display: unset !important;
  margin: 0 7px 10px;
}

.reservationPanel .-form {
  justify-content: center;
}

.reservationPanel .-form label {
  display: block !important;
  font-size: 12px;
  clear: both;
  margin: 0 0 2px -9px;
  padding: 5px 0 0px 17px;
}

.reservationPanel .-form h5 {
  color: #fff;
  padding: 5px 30px;
  /* font-family:  Lato-Black,sans-serif; */
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #666666;
  font-size: 14px;
  letter-spacing: .3px;
  margin-top: 4px;
  padding-left: 40px;
  font-size: 21px;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  padding: 15px 30px;
}

@media (min-width: 576px) {
  .reservationPanel .-form h5 {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .reservationPanel .-form h5 {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .reservationPanel .-form h5 {
    font-size: 52px;
  }
}

@media (min-width: 1200px) {
  .reservationPanel .-form h5 {
    width: 250px;
    text-align: center;
    padding: 5px 30px;
  }
}

.reservationPanel .-form input, .reservationPanel .-form select {
  margin: 0 5px;
  background-color: #fff;
  border: 1px solid #d1d1d1;
  outline: 0 !important;
  box-shadow: none !important;
  color: #000;
  border-radius: 0;
  padding: 0 10px 0 10px;
  max-width: 365px;
  font-size: 12px;
  cursor: pointer;
  width: 170px;
  min-height: 16px;
  /* font-family:  Lato,sans-serif; */
  font-family: 'Montserrat', sans-serif;
}

@media (min-width: 767px) {
  .reservationPanel .-form input, .reservationPanel .-form select {
    font-size: 16px;
  }
}

@media (min-width: 870px) {
  .reservationPanel .-form input, .reservationPanel .-form select {
    font-size: 16px;
  }
}

@media (min-width: 1300px) {
  .reservationPanel .-form input, .reservationPanel .-form select {
    width: 175px;
  }
}

.reservationPanel .-form input:focus, .reservationPanel .-form select:focus {
  background-color: #fff;
  border: 0;
  outline: 0 !important;
  box-shadow: none !important;
  color: #000;
}

.reservationPanel .-form input.inputDateIn, .reservationPanel .-form select.inputDateIn {
  background-image: url("../images/kalendarz.svg");
  background-repeat: no-repeat;
  background-position: 90% center;
  background-size: 16px 16px;
}

.reservationPanel .-form input.inputDateOut, .reservationPanel .-form select.inputDateOut {
  background-image: url("../images/kalendarz.svg");
  background-repeat: no-repeat;
  background-position: 90% center;
  background-size: 16px 16px;
}

.reservationPanel .-form input.promocode, .reservationPanel .-form select.promocode {
  background-image: url("../images/promoCode_bg.png");
  background-repeat: no-repeat;
  background-position: 90% center;
}

.reservationPanel .-form select {
  /* font-family:  Lato,sans-serif; */
  font-family: 'Montserrat', sans-serif;
}

.reservationPanel .-form select option {
  padding: 5px;
}

.reservationPanel .-form button {
  font-weight: bold;
  background-color: #5B5CD8;
  display: block !important;
  border-radius: 0;
  color: #fff;
  /* font-family:  Lato,sans-serif; */
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0;
  margin: 15px 0 15px 0 !important;
  padding: 0;
  width: 200px;
  height: 74px;
  letter-spacing: 3px;
  font-weight: 400;
}

@media (max-width: 1200px) {
  .reservationPanel .-form button {
    width: 150px;
    height: 50px;
  }
}

@media (min-width: 1200px) {
  .reservationPanel .-form button {
    margin: 0 0 0 auto !important;
  }
}

.reservationPanel .-form button:before {
  border: #fff solid 1px;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
}

.reservationPanel #apartmentSelect {
  max-width: 225px;
  width: 225px;
}

/* EVENTS CALENDAR */
div.ba-calendar {
  background-color: #c1c0b8;
  border: 5px solid #555;
  color: #666666;
  display: block;
  margin: 0 0 20px 0;
  padding: 0;
  position: relative;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  width: 240px;
}

div.ba-calendasr.fixed {
  position: fixed;
  top: 150px;
  z-index: 50;
}

div.ba-calendar table {
  background-color: #ede6df !important;
  border: 1px solid #c1c0b8 !important;
  border-collapse: separate;
  margin: 0;
  width: 100%;
}

div.ba-calendar table th {
  background-color: #819097;
  border: 1px solid #819097 !important;
  border-bottom: 1px solid #c1c0b8 !important;
  color: #FFF;
  font-size: 18px;
  line-height: 20px;
  margin: 0;
  padding: 10px 0;
  text-align: center;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  text-transform: none;
}

div.ba-calendar table td {
  background-color: #f5f6f7 !important;
  border: 1px solid #f5f6f7 !important;
  border-left: 1px solid #f5f6f7 !important;
  border-top: 1px solid #f5f6f7 !important;
  color: #c1c0b8 !important;
  cursor: default !important;
  font-size: 12px;
  line-height: 30px;
  padding: 0;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}

div.ba-calendar table td.calendarDayHeading {
  background-color: #819097 !important;
  border: 1px solid #819097 !important;
  color: #ede6df !important;
  height: 10px;
  padding: 0;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}

div.ba-calendar table td.calendarCell {
  background-color: #c1c0b8 !important;
  border: 1px solid #819097 !important;
  border-left: 1px solid #f5f6f7 !important;
  border-top: 1px solid #f5f6f7 !important;
  color: #555 !important;
  width: 14.28%;
}

div.ba-calendar table td.calendarCell.-with-event {
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.05)), color-stop(100%, rgba(0, 0, 0, 0.05))) !important;
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26ffffff', endColorstr='#26000000',GradientType=0 ) !important;
  /* IE6-9 */
  background-color: #a38461 !important;
  border: 1px solid #a38461 !important;
  border-left: 1px solid #806341 !important;
  border-top: 1px solid #806341 !important;
  color: #ede6df !important;
  text-shadow: 0 1px 0 #806341 !important;
}

div.ba-calendar table td.calendarCell.-with-event a {
  color: #ede6df !important;
  display: block;
  text-decoration: none;
}

div.ba-calendar table td.calendarCell.-with-event a:hover {
  color: #FFF !important;
}

div.ba-calendar table td.calendarToday {
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.05)), color-stop(100%, rgba(0, 0, 0, 0.05))) !important;
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26ffffff', endColorstr='#26000000',GradientType=0 ) !important;
  /* IE6-9 */
  background-color: #555 !important;
  border: 1px solid #555 !important;
  border-left: 1px solid #2d373b !important;
  border-top: 1px solid #2d373b !important;
  color: #c1c0b8 !important;
  text-shadow: 0 1px 0 #2d373b;
}

div.ba-calendar table td.calendarToday a {
  color: #f5f6f7 !important;
  display: block;
  text-decoration: none;
}

div.ba-calendar table td.calendarToday a:hover {
  color: #FFF !important;
}

/* calendar root element */
#calroot {
  /* place on top of other elements. set a higher value if nessessary */
  background-color: #6ED2BA;
  border: 5px solid #6ED2BA;
  color: #666666;
  display: none;
  margin: -15px 0 0 0;
  padding: 1px;
  position: absolute;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  top: 290px;
  width: 252px;
  z-index: 600;
}

body.ba-tpl-index #calroot {
  position: fixed !important;
}

/* head. contains title, prev/next month controls and possible month/year selectors */
#calhead {
  background-color: #5c5d60;
  height: 40px;
  padding: 10px 0px 0px 0px;
}

#caltitle {
  color: #FFF;
  float: left;
  font-size: 18px;
  line-height: 20px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  width: 200px;
}

#calnext, #calprev, .calnext, .calprev {
  background-image: url("../images/left-arrow.png");
  background-position: 0px 0px;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  display: block;
  float: left;
  height: 25px;
  margin: 0 0 0 10px;
  width: 11px;
}

#calnext, .calnext {
  background-image: url("../images/right-arrow.png");
  background-position: center !important;
  margin: -20px 10px 0 0;
  width: 15px;
  height: 20px;
  float: right;
}

#calprev, .calprev {
  background-position: center !important;
  width: 15px;
  height: 20px;
}

#calprev:hover, .calprev:hover {
  background-position: 0px -25px;
}

#calnext:hover, .calnext:hover {
  background-position: 0px -75px;
}

#calprev.caldisabled, #calnext.caldisabled {
  visibility: hidden;
}

/* year/month selector */
#caltitle select {
  font-size: 10px;
}

/* names of the days */
#caldays {
  /*background-color: #333;*/
  border-top: 1px solid #CCC;
  color: #fff;
  font-size: 12px;
  height: 15px;
  padding: 10px 0px 5px 0px;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
}

#caldays span {
  display: block;
  float: left;
  font-size: 12px;
  text-align: center;
  width: 36px;
}

/* container for weeks */
#calweeks {
  margin-top: 0px;
}

/* single week */
.calweek {
  clear: left;
  height: 25px;
}

/* single day */
.calweek a {
  background-color: #e2e1e0;
  border: 1px solid #fff;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  color: #555;
  display: block;
  float: left;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
  margin: 0px;
  padding: 0px;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  width: 34px;
}

/* different states */
.calweek a:hover, .calfocus {
  /*color: #ab050d !important;*/
}

/* sunday */
a.calsun {
  /*color: ab050d;*/
}

/* offmonth day */
a.caloff {
  background-color: #f5f6f7 !important;
  border: 1px solid #f5f6f7 !important;
  color: #c1c0b8 !important;
  cursor: default !important;
}

a.caloff:hover {
  background-color: #f5f6f7 !important;
  color: #c1c0b8 !important;
  cursor: default !important;
}

/* unselecteble day */
a.caldisabled {
  background-color: #f5f6f7 !important;
  border: 1px solid #f5f6f7 !important;
  color: #c1c0b8 !important;
  cursor: default !important;
}

a.caldisabled:hover {
  background-color: #f5f6f7 !important;
  color: #c1c0b8 !important;
  cursor: default !important;
}

/* current date */
#calcurrent {
  background-color: #988e6f !important;
  border: 1px solid #988e6f !important;
  border-left: none !important;
  border-top: none !important;
  color: #ede6df !important;
  text-shadow: 0 1px 0 #767575 !important;
}

#calcurrent:hover {
  color: #FFF !important;
}

/* today */
#caltoday {
  background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.05)), color-stop(100%, rgba(0, 0, 0, 0.05))) !important;
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%) !important;
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26ffffff', endColorstr='#26000000',GradientType=0 ) !important;
  /* IE6-9 */
  background-color: #555 !important;
  border: 1px solid #555 !important;
  border-left: 1px solid #2d373b !important;
  border-top: 1px solid #2d373b !important;
  color: #c1c0b8 !important;
  text-shadow: 0 1px 0 #2d373b;
}

#caltoday:hover {
  color: #FFF !important;
}

#caldays {
  height: 14px;
}

#caldays span {
  display: block;
  float: left;
  width: 33px;
  text-align: center;
}

#caldays {
  height: 28px;
  display: flex;
  width: 100%;
  padding: 5px 0px 5px 0px;
}

#caldays span {
  float: inherit;
  width: 34px;
  padding: 3px 0px;
  text-align: center;
  display: inline-block;
}

.mobileBottomPanel {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3000;
  background-color: #5b5cd8;
  display: none;
  padding: 0;
}

.mobileBottomPanel .item {
  background-color: #5b5cd8;
  padding: 7px;
  text-align: center;
  width: 100%;
}

.mobileBottomPanel .item:hover {
  cursor: pointer;
  opacity: .7;
}

.mobileBottomPanel .item a:hover {
  text-decoration: none;
}

.mobileBottomPanel .item img {
  max-width: 18px;
}

.mobileBottomPanel .item p {
  color: #fff;
  font-size: 10px;
  margin-top: 3px;
  text-transform: uppercase;
}

.mobileBottomPanel .item.book {
  background-color: #fff;
}

.mobileBottomPanel .item.book p {
  color: #5b5cd8;
}

#cookie-msg {
  padding: 12px !important;
  background-color: #28487e !important;
  z-index: 100;
}

#cookie-msg .msg {
  font-size: 12px;
}

@media (min-width: 576px) {
  #cookie-msg .msg {
    font-size: 14px;
  }
}

#cookie-msg a {
  color: #E35205 !important;
}

#cookie-msg .btn-aceptar {
  background-color: #E35205 !important;
  margin-left: 13px;
  font-size: 13px !important;
  color: #28487e !important;
  margin: 2px;
  display: inline-block;
}

#navbarNavDropdown {
  justify-content: center;
}

.navbar {
  background-color: transparent;
  transition: .2s;
  padding: 0;
}

.navbar-brand__logo {
  width: 100px;
  transition: .2s;
}

@media (min-width: 576px) {
  .navbar-brand__logo {
    width: 110px;
  }
}

@media (min-width: 768px) {
  .navbar-brand__logo {
    width: 110px;
  }
}

@media (min-width: 992px) {
  .navbar-brand__logo {
    width: 120px;
  }
}

@media (min-width: 1200px) {
  .navbar-brand__logo {
    width: 130px;
  }
}

@media (min-width: 1410px) {
  .navbar-brand__logo {
    width: 140px;
  }
}

/* dla menu przyczepionego */
.header--fixed .navbar-collapse .nav-item .nav-link {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
}

.header--fixed .navbar-collapse .nav-item .nav-link:hover {
  text-decoration: underline;
}

.header--fixed .navbar-collapse .nav-item--book .nav-link {
  margin: -2px 10px -6px;
}

@media (min-width: 992px) {
  .header--fixed .navbar-collapse .nav-item {
    padding: 12px 0 !important;
  }
}

/* koniec dla menu przyczepionego */
.navbar-collapse {
  padding: 10px 0;
}

@media (min-width: 992px) {
  .navbar-collapse {
    padding: 0;
  }
}

.navbar-collapse .nav-item:nth-child(n) {
  margin-right: 20px;
}

@media (min-width: 992px) {
  .navbar-collapse .nav-item:nth-child(n) {
    margin-right: 2px;
  }
}

@media (min-width: 1200px) {
  .navbar-collapse .nav-item:nth-child(n) {
    margin-right: 10px;
  }
}

.navbar-collapse .nav-item.dropdown > a {
  padding-right: 27px !important;
  background: url("../images/downArrow.png") no-repeat top 50% right 10px;
}

.navbar-collapse .nav-item .nav-link {
  /* font-family:  Lato,sans-serif; */
  font-family: 'Montserrat', sans-serif;
  color: #666666;
  text-transform: uppercase;
  margin: 2px 5px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: .3px;
  display: flex;
  align-items: center;
  padding: 10px 5px 5px 0;
}

@media (min-width: 992px) {
  .navbar-collapse .nav-item .nav-link {
    font-size: 10px;
    padding: 5px 0px 5px 0 !important;
  }
}

@media (min-width: 1200px) {
  .navbar-collapse .nav-item .nav-link {
    font-size: 12px;
    margin: 0 3px;
  }
}

@media (min-width: 1410px) {
  .navbar-collapse .nav-item .nav-link {
    margin: 0 5px;
    padding: 9px nn5px 5px 0 !important;
    font-size: 14px;
  }
}

.navbar-collapse .nav-item .nav-link:hover {
  text-decoration: underline;
}

.navbar-collapse .nav-item.active > .hvr-border-fade {
  /* box-shadow: inset 0 0 0 1px #fff, 0 0 1px rgba(0, 0, 0, 0); */
  text-decoration: underline;
}

.navbar-collapse .nav-item--book {
  opacity: .93;
  transition: .18s;
  margin-bottom: 5px;
}

.navbar-collapse .nav-item--book:hover {
  opacity: 1;
}

.navbar-collapse .nav-item--book .nav-link {
  background-color: #988e6f;
  padding: 10px 15px 5px !important;
  margin: 3px 10px 0px 10px;
}

.navbar-collapse .nav-item--book .nav-link::before {
  content: '';
  position: absolute;
  border: #fff solid 1px;
  top: -6px;
  right: -6px;
  bottom: -6px;
  left: -6px;
}

@media (min-width: 992px) {
  .navbar-collapse .nav-item--book .nav-link {
    margin: -4px 10px;
    padding: 10px 15px !important;
  }
}

@media (min-width: 992px) {
  .navbar-collapse .dropdown:hover .dropdown-menu {
    display: block !important;
  }
}

.navbar-collapse .dropdown .dropdown-menu {
  background-color: transparent;
  border: 0 !important;
  min-width: 400px;
}

@media (max-width: 992px) {
  .navbar-collapse .dropdown .dropdown-menu {
    padding-top: 0;
    padding-left: 7px;
  }
}

@media (min-width: 992px) {
  .navbar-collapse .dropdown .dropdown-menu {
    margin-top: -5px;
    display: none;
    background-color: rgba(152, 142, 111, 0.8);
  }
}

.navbar-collapse .dropdown .dropdown-menu .nav-link {
  border-bottom: 1px solid #fff !important;
  transition: .2s;
}

@media (min-width: 992px) {
  .navbar-collapse .dropdown .dropdown-menu .nav-link {
    padding: 11px 7px 9px 7px !important;
  }
}

.navbar-collapse .dropdown .dropdown-menu .nav-link.hvr-border-fade {
  border: 0 !important;
  border-bottom: 1px solid #fff !important;
}

.navbar-collapse .dropdown .dropdown-menu .nav-link.hvr-border-fade:hover {
  border: 0 !important;
  border-bottom: 1px solid #988d6e !important;
  box-shadow: none !important;
}

.lang-item {
  margin-top: 5px;
}

.page-id-2893 .welcome,
.page-template-page-galeria .welcome {
  background: none;
}

.welcome {
  background-color: #f4f7fe;
  background: url("../images/angelhouse-napis.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.welcome .titleSection {
  padding-bottom: 0px;
  color: #988d6e;
  /* font-family:  Lato-Black,sans-serif; */
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #666666;
  font-size: 14px;
  margin-top: .6rem;
  margin-bottom: 3rem;
  color: #5C5D60;
  text-transform: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  text-transform: none;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #5C5D60;
  font-weight: 700;
  text-align: center;
}

@media (min-width: 576px) {
  .welcome .titleSection {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .welcome .titleSection {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .welcome .titleSection {
    font-size: 52px;
  }
}

@media (min-width: 576px) {
  .welcome .titleSection {
    padding-bottom: 0px;
  }
}

.welcome .titleSection a {
  color: #5C5D60;
}

@media (min-width: 576px) {
  .welcome .titleSection {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .welcome .titleSection {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .welcome .titleSection {
    font-size: 48px;
  }
}

@media (min-width: 576px) {
  .welcome .titleSection {
    padding-bottom: 0px;
  }
}

.welcome .subtitleSection {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-align: center;
  letter-spacing: 2px;
  line-height: 24px;
  color: #5C5D60;
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .welcome .subtitleSection {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .welcome .subtitleSection {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .welcome .subtitleSection {
    font-size: 16px;
  }
}

.welcome .textWrap {
  max-width: 930px;
  margin: 0 auto;
  text-align: justify;
}

.welcome .textWrap p + p {
  padding-top: 15px;
}

@media (max-width: 992px) {
  .welcome img {
    padding-top: 30px;
  }
}

.offer {
  overflow: hidden;
}

.offer .item {
  margin-bottom: 35px;
}

.offer .item .photoWrap {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 220px;
}

@media (min-width: 450px) {
  .offer .item .photoWrap {
    min-height: 250px;
  }
}

@media (min-width: 576px) {
  .offer .item .photoWrap {
    min-height: 350px;
  }
}

.offer .item .textWrap .textBox {
  max-width: 700px;
  margin: 0 auto;
  padding: 40px 15px 50px 15px;
}

.offer .item .textWrap .textBox .titleSection {
  text-shadow: 2px 2px 7px #fff;
  text-align: left;
  padding-top: 0;
  padding-bottom: 15px;
}

@media (min-width: 1200px) {
  .offer .item .textWrap .textBox .titleSection {
    line-height: 60px;
  }
}

.offer .item .textWrap .textBox p {
  font-size: 14px;
  text-align: justify;
}

.offer .item .textWrap .textBox .btnBox {
  padding-top: 30px;
}

@media (min-width: 768px) {
  .offer .item:nth-of-type(odd) .textWrap .textBox .titleSection {
    margin-left: -60px;
    position: relative;
    z-index: 2;
  }
}

@media (min-width: 1500px) {
  .offer .item:nth-of-type(odd) .textWrap .textBox .titleSection {
    margin-left: -100px;
  }
}

@media (min-width: 1600px) {
  .offer .item:nth-of-type(odd) .textWrap .textBox .titleSection {
    margin-left: -110px;
  }
}

@media (min-width: 1600px) {
  .offer .item:nth-of-type(odd) .textWrap .textBox .titleSection {
    margin-left: -120px;
  }
}

@media (min-width: 1700px) {
  .offer .item:nth-of-type(odd) .textWrap .textBox .titleSection {
    margin-left: -140px;
  }
}

@media (min-width: 1800px) {
  .offer .item:nth-of-type(odd) .textWrap .textBox .titleSection {
    margin-left: -160px;
  }
}

@media (min-width: 768px) {
  .offer .item:nth-of-type(even) .textWrap .textBox .titleSection {
    margin-right: -60px;
    position: relative;
    z-index: 2;
  }
}

@media (min-width: 1500px) {
  .offer .item:nth-of-type(even) .textWrap .textBox .titleSection {
    margin-right: -100px;
  }
}

@media (min-width: 1600px) {
  .offer .item:nth-of-type(even) .textWrap .textBox .titleSection {
    margin-right: -110px;
  }
}

@media (min-width: 1600px) {
  .offer .item:nth-of-type(even) .textWrap .textBox .titleSection {
    margin-right: -120px;
  }
}

@media (min-width: 1700px) {
  .offer .item:nth-of-type(even) .textWrap .textBox .titleSection {
    margin-right: -140px;
  }
}

@media (min-width: 1800px) {
  .offer .item:nth-of-type(even) .textWrap .textBox .titleSection {
    margin-right: -160px;
  }
}

.newsletterrrr div.wpcf7 .ajax-loader {
  position: absolute;
  margin-top: 8px;
}

.newsletterrrr .wpcf7-form-control-wrap {
  position: static;
  width: 100%;
}

.newsletterrrr .wpcf7-form-control-wrap span.wpcf7-not-valid-tip {
  width: 100%;
  font-size: 12px;
}

.newsletterrrr .wpcf7-response-output.wpcf7-display-none.wpcf7-validation-errors,
.newsletterrrr div.wpcf7-mail-sent-ng, .newsletterrrr div.wpcf7-aborted {
  border: none;
  color: #fff;
  font-size: 13px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 0px;
}

.newsletterrrr div.wpcf7-mail-sent-ok {
  border: none !important;
  color: #398f14 !important;
  text-align: center !important;
  font-weight: bold !important;
  margin: 10px 0px !important;
}

.newsletterrrr .btnBox input[type=checkbox] {
  opacity: 0;
}

.newsletterrrr .formWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 35px;
  margin-top: 30px;
}

@media (min-width: 768px) {
  .newsletterrrr .formWrap {
    padding: 20px;
  }
}

@media (min-width: 1400px) {
  .newsletterrrr .formWrap {
    padding: 25px 35px;
  }
}

.newsletterrrr .formWrap h5 {
  color: #fff;
}

.newsletterrrr .formWrap input[type=submit] {
  border: 1px solid #fff;
  background: none;
  padding: 10px;
}

.newsletterrrr .formWrap input[type=submit]:hover {
  box-shadow: none !important;
  color: #156f93 !important;
  background: #fff;
  border: 1px solid #156f93 !important;
  padding: 10px !important;
}

.newsletterrrr .agree {
  margin-top: -11px;
  margin-bottom: 10px;
}

.burger {
  width: 45px;
  height: 30px;
  display: inline-block;
  margin-right: 10px;
  transition: .3s;
  margin: -11px 0 0 auto;
  background-color: transparent;
  border: 0;
}

.burger:hover, .burger:active, .burger:focus {
  outline: 0;
}

@media (min-width: 992px) {
  .burger {
    display: none;
  }
}

.burger span {
  display: block;
  width: 35px;
  height: 2px;
  background-color: #5b5cd8;
  margin-top: 6px;
  transition: .3s;
}

.burger span:nth-child(2) {
  transition: .1s !important;
}

.burger.active {
  margin-top: -20px;
}

.burger.active span:nth-child(1) {
  transform: rotate(45deg);
  margin-top: 13px;
}

.burger.active span:nth-child(2) {
  opacity: 0;
}

.burger.active span:nth-child(3) {
  transform: rotate(-45deg);
  margin-top: -10px;
}

.specialForm .titleSection {
  margin-top: .6rem;
  margin-bottom: 3rem;
  color: #5C5D60;
  text-transform: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  text-transform: none;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #5C5D60;
  font-weight: 700;
  text-align: center;
}

.specialForm .titleSection a {
  color: #5C5D60;
}

@media (min-width: 576px) {
  .specialForm .titleSection {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .specialForm .titleSection {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .specialForm .titleSection {
    font-size: 48px;
  }
}

@media (min-width: 576px) {
  .specialForm .titleSection {
    padding-bottom: 0px;
  }
}

.specialForm h5 {
  padding-bottom: 25px;
  text-transform: uppercase;
  color: #eaeaea;
  font-size: 16px;
  text-transform: lowercase;
}

.specialForm .form-group {
  margin-bottom: 25px;
}

.specialForm input, .specialForm textarea {
  box-shadow: 0 !important;
  padding: 10px 10px;
  transition: .3s;
  color: #000 !important;
  display: block;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #fff;
  border-radius: 3px;
  font-size: 1rem;
  font-weight: 300;
  text-transform: uppercase;
  background-color: #EDECEB;
  transition: color 0.3s, box-shadow 0.3s;
}

.specialForm input:focus, .specialForm textarea:focus {
  box-shadow: none !important;
}

.specialForm .form-control:focus {
  background-color: #EDECEB;
  border-bottom: 2px solid #fff;
}

.specialForm .wpcf7-submit {
  background-color: #5b5cd8;
  display: block !important;
  border-radius: 0;
  color: #fff !important;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  margin: 15px auto !important;
  width: 200px;
  height: 45px;
  padding: 7px 0 5px 0;
  letter-spacing: 3px;
  font-weight: 400;
  border: 1px solid #5b5cd8;
}

.specialForm .wpcf7-submit:hover {
  background: #fff;
  border: 1px solid #5b5cd8;
  color: #5b5cd8 !important;
  text-decoration: none;
}

.specialForm .form-check-label {
  font-weight: 300;
}

.specialForm .checkWrap .form-group {
  margin-bottom: 7px;
}

.specialForm a {
  color: #000;
  text-transform: uppercase;
  text-decoration: underline;
}

.specialForm .agree {
  display: flex;
  font-size: 12px;
}

@media (min-width: 768px) {
  .specialForm .agree {
    font-size: 13px;
  }
}

@media (min-width: 1200px) {
  .specialForm .agree {
    font-size: 14px;
  }
}

.specialForm .agree p {
  font-size: 12px;
}

@media (min-width: 768px) {
  .specialForm .agree p {
    font-size: 13px;
  }
}

@media (min-width: 1200px) {
  .specialForm .agree p {
    font-size: 14px;
  }
}

.specialForm .showFormPanel {
  padding-top: 8px;
  padding-bottom: 15px;
}

.specialForm .showFormPanel .blueLine {
  width: 100%;
  height: 2px;
  background-color: #8e9ba4;
}

.specialForm .showFormPanel img {
  margin-top: -32px;
  position: relative;
  z-index: 3;
}

.specialForm .showFormPanel img:hover {
  cursor: pointer;
}

.specialForm .showFormPanel .showFormText {
  color: #28487e;
  font-size: 13px;
  margin-top: -6px;
}

.specialForm .formWrap {
  display: none;
}

.specialForm .otherInfo p {
  color: #28487e;
  font-size: 13px;
}

.specialForm span.wpcf7-not-valid-tip {
  font-size: 13px !important;
  margin-left: 15px;
  padding-right: 15px;
}

.specialForm div.wpcf7-validation-errors, .specialForm div.wpcf7-acceptance-missing {
  border: 0 !important;
  text-align: center;
  color: #ff0000;
}

.specialForm .form-check-input {
  margin-top: -11px;
}

.specialForm .form-check-input:hover {
  cursor: pointer;
}

.specialForm .div.wpcf7-mail-sent-ok {
  color: #398f14;
  border: none;
  text-align: center;
}

.specialForm div.wpcf7-response-output {
  margin-top: 0 !important;
}

div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
  border: 0 !important;
  text-align: center;
  color: #ff0000;
}

.banners {
  padding-top: 35px;
  padding-bottom: 15px;
}

.banners .item {
  height: 250px;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  .banners .item {
    margin-bottom: 1rem;
  }
}

.banners .item:hover .textWrap {
  opacity: 1;
}

.banners .item:hover .title {
  opacity: 0;
}

@media (min-width: 365px) {
  .banners .item {
    height: 350px;
  }
}

.banners .item .title {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: .4s;
  opacity: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.banners .item .title h5 {
  background-color: #5b5cd8;
  color: #fff;
  padding: 15px 15px;
  font-size: 18px;
}

@media (min-width: 576px) {
  .banners .item .title h5 {
    padding: 15px 25px;
  }
}

@media (min-width: 768px) {
  .banners .item .title h5 {
    font-size: 20px;
  }
}

.banners .item .textWrap {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  opacity: 0;
  transition: .4s;
}

.banners .item .textWrap .textBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 25px;
  text-align: center;
}

.banners .item .textWrap .textBox p {
  color: #fff;
}

.banners .item .textWrap .textBox .btnBox {
  margin-top: 20px;
}

.banners .item .textWrap .textBox .btnBox a {
  font-size: 15px;
}

.Opinions {
  margin-top: 4rem;
}

.Opinions__titleSection {
  margin-top: .6rem;
  margin-bottom: 3rem;
  color: #5C5D60;
  text-transform: capitalize;
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  text-transform: none;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #5C5D60;
  font-weight: 700;
}

.Opinions__titleSection a {
  color: #5C5D60;
}

@media (min-width: 576px) {
  .Opinions__titleSection {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .Opinions__titleSection {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .Opinions__titleSection {
    font-size: 48px;
  }
}

@media (min-width: 576px) {
  .Opinions__titleSection {
    padding-bottom: 0px;
  }
}

.Opinions__subtitleSection {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-align: center;
  letter-spacing: 2px;
  line-height: 24px;
  color: #5C5D60;
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .Opinions__subtitleSection {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .Opinions__subtitleSection {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .Opinions__subtitleSection {
    font-size: 16px;
  }
}

.OpinionsSlider__Item__Body {
  padding: 4rem 2rem;
  margin: 0 1rem;
  background-color: #D3F1F2;
}

.OpinionsSlider__Item__Body__author p {
  font-size: 16px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 0.5rem;
  text-align: center;
  letter-spacing: 2px;
}

.OpinionsSlider__Item__Body img {
  text-align: center;
  width: 20px;
  height: 4px;
  margin: 0 auto;
  margin-bottom: .4rem;
}

.OpinionsSlider__Item__Body__Text {
  padding-bottom: 2rem;
}

.OpinionsSlider__Item__Body__Text p {
  font-size: 16px;
  font-weight: 300;
}

.OpinionsSlider__Item__Body__Link a {
  /* font-family: 'Roboto-Medium', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  display: flex;
  color: #5B5CD8;
}

.OpinionsSlider__Item__Body__Link a img {
  padding-left: 10px;
}

.opinions {
  background-color: #f4f7fe;
}

.opinions .titleSection {
  padding-bottom: 0px;
  color: #887846;
  /* font-family:  Lato-Black,sans-serif; */
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-style: normal;
  color: #666666;
  font-size: 14px;
}

@media (min-width: 576px) {
  .opinions .titleSection {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .opinions .titleSection {
    font-size: 38px;
  }
}

@media (min-width: 992px) {
  .opinions .titleSection {
    font-size: 52px;
  }
}

@media (min-width: 576px) {
  .opinions .titleSection {
    padding-bottom: 0px;
  }
}

.opinions .subtitleSection {
  color: #666666;
  padding-bottom: 45px;
  line-height: 35px;
  font-size: 24px;
}

@media (min-width: 576px) {
  .opinions .subtitleSection {
    font-size: 24px;
  }
}

@media (min-width: 768px) {
  .opinions .subtitleSection {
    font-size: 26px;
  }
}

@media (min-width: 992px) {
  .opinions .subtitleSection {
    font-size: 28px;
  }
}

.opinions-slider-item {
  padding: 10px 60px;
}

@media (min-width: 992px) {
  .opinions-slider-item {
    padding: 10px 50px;
  }
}

.opinions-slider-item__logo {
  text-align: center;
  height: 60px;
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}

.opinions-slider-item__logo img {
  margin: 0 auto;
}

.opinions-slider-item__text {
  text-align: justify;
  padding: 12px 0;
  max-width: 930px;
  margin: 0 auto;
}

.opinions-slider-item__author {
  text-align: center;
  padding-top: 10px;
  font-weight: 600;
}

.opinions-slider-item__author > * {
  font-weight: 600;
}

.opinions-slider .slick-arrow {
  width: 30px;
  height: 30px;
  background-size: cover;
  z-index: 22;
  font-size: 0 !important;
  opacity: .9;
  transition: .2s;
}

@media (min-width: 992px) {
  .opinions-slider .slick-arrow {
    width: 60px;
    height: 60px;
  }
}

.opinions-slider .slick-arrow:hover {
  opacity: 1;
}

.opinions-slider .slick-arrow:before {
  content: '';
}

.opinions-slider .slick-prev {
  background-image: url("../images/opinions_prev.jpg");
  left: 5px;
}

.opinions-slider .slick-next {
  background-image: url("../images/opinions_next.png");
  right: 5px;
}

.Udogodnienia {
  margin-top: 4rem;
  background: #fff;
  overflow: hidden;
}

.Udogodnienia__titleSection {
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  text-transform: none;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #5C5D60;
  text-align: center;
}

.Udogodnienia__titleSection a {
  color: #5C5D60;
}

@media (min-width: 576px) {
  .Udogodnienia__titleSection {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .Udogodnienia__titleSection {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .Udogodnienia__titleSection {
    font-size: 48px;
  }
}

@media (min-width: 576px) {
  .Udogodnienia__titleSection {
    padding-bottom: 0px;
  }
}

.Udogodnienia__icons {
  text-align: center;
}

.Udogodnienia__icons img {
  height: 30px;
  width: auto;
}

@media (min-width: 576px) {
  .Udogodnienia__icons img {
    height: 70px;
  }
}

.Udogodnienia__icons__txt {
  color: #666666;
  /* font-family: 'Lato',sans-serif; */
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

@media (min-width: 460px) {
  .Udogodnienia__icons__txt {
    font-size: 16px;
    padding-top: 2rem;
  }
}

.Udogodnienia__BgColors {
  margin: 3rem 0 0;
}

.Udogodnienia__BgOrange {
  background: #F2A50A 0% 0% no-repeat padding-box;
  height: 9px;
}

.Udogodnienia__BgRed {
  background: #C61A59 0% 0% no-repeat padding-box;
  height: 9px;
}

.Udogodnienia__BgBlue {
  background: #0C41AA 0% 0% no-repeat padding-box;
  height: 9px;
}

.Udogodnienia__BgGreen {
  background: #0D8473 0% 0% no-repeat padding-box;
  height: 9px;
}

.container-fluid-paralax {
  width: 100% !important;
}

.listOfferTextSlider {
  padding-bottom: 0;
}

.ApartamentySlider {
  padding-bottom: 0;
  margin-top: 4rem;
}

.ApartamentySlider__titleSection {
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  text-transform: none;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #5C5D60;
  text-align: center;
  margin-bottom: 5rem;
}

.ApartamentySlider__titleSection a {
  color: #5C5D60;
}

@media (min-width: 576px) {
  .ApartamentySlider__titleSection {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .ApartamentySlider__titleSection {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .ApartamentySlider__titleSection {
    font-size: 48px;
  }
}

@media (min-width: 576px) {
  .ApartamentySlider__titleSection {
    padding-bottom: 0px;
  }
}

.ApartamentySlider__subtitleSection {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-align: center;
  letter-spacing: 2px;
  line-height: 24px;
  color: #5C5D60;
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .ApartamentySlider__subtitleSection {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .ApartamentySlider__subtitleSection {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .ApartamentySlider__subtitleSection {
    font-size: 16px;
  }
}

.ApartamentySlider__item {
  height: 563px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 330px;
  /* @include overlay(#000, 0.0); */
}

@media (min-width: 576px) {
  .ApartamentySlider__item {
    height: 563px;
  }
}

@media (min-width: 768px) {
  .ApartamentySlider__item {
    height: 563px;
  }
}

@media (min-width: 992px) {
  .ApartamentySlider__item {
    height: 563px;
  }
}

@media (min-width: 1200px) {
  .ApartamentySlider__item {
    height: 563px;
  }
}

@media (min-width: 1400px) {
  .ApartamentySlider__item {
    height: 563px;
  }
}

@media (min-width: 1600px) {
  .ApartamentySlider__item {
    height: 563px;
  }
}

.ApartamentySlider__item.onMobile {
  display: block;
}

@media (min-width: 768px) {
  .ApartamentySlider__item.onMobile {
    display: none;
  }
}

.ApartamentySlider__item.onDesktop {
  display: none;
}

@media (min-width: 768px) {
  .ApartamentySlider__item.onDesktop {
    display: block;
  }
}

.ApartamentySlider__item.onMobile.onDesktop {
  display: block;
}

.ApartamentySlider__item__textBoxBG {
  display: flex;
}

.ApartamentySlider__item__textBoxBG__titleOffer {
  margin-top: .6rem;
  margin-left: 0.6rem;
  color: #5C5D60;
  /* text-transform: capitalize; */
  /* font-family: 'Lato',sans-serif; */
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  padding-right: 2rem;
}

@media (min-width: 576px) {
  .ApartamentySlider__item__textBoxBG__titleOffer {
    padding-bottom: 0px;
  }
}

.ApartamentySlider__item__textBoxBG__titleOffer a {
  color: #5c5d60;
}

.ApartamentySlider__item__textBoxBG__titleOffer a:hover {
  text-decoration: none;
  color: #000;
}

.ApartamentySlider__item__textBox {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 270px;
  height: 87px;
  background: #fff;
  padding: 1rem;
}

.ApartamentySlider__item__ikona {
  width: 27px !important;
  height: 38px;
  padding: 0;
  margin: 0.5rem;
}

.ApartamentySlider__item__LinkButton {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0;
}

.ApartamentySlider__item__LinkButton a {
  width: 50px;
  height: 50px;
  display: block;
  z-index: 1111111111;
}

.ApartamentySlider__item.onMobile {
  display: block;
}

@media (min-width: 768px) {
  .ApartamentySlider__item.onMobile {
    display: none;
  }
}

.ApartamentySlider__item.onDesktop {
  display: none;
}

@media (min-width: 768px) {
  .ApartamentySlider__item.onDesktop {
    display: block;
  }
}

.ApartamentySlider__item.onMobile.onDesktop {
  display: block;
}

.ApartamentySlider .owl-theme .owl-nav {
  width: 100%;
  max-width: 1630px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  height: 0;
  margin-top: -40px;
}

.ApartamentySlider .owl-theme .owl-dots {
  position: absolute;
  bottom: 10px;
  text-align: center;
  margin: 0 auto;
  left: 50%;
  transform: translate(-50%);
}

.ApartamentySlider .owl-theme .owl-dots .owl-dot span {
  margin: 5px 4px;
  background-color: #fff;
}

.ApartamentySlider .owl-theme .owl-dots .owl-dot.active span, .ApartamentySlider .owl-theme .owl-dots .owl-dot:hover span {
  background-color: #D6D6D6;
}

.ApartamentySlider .owl-carousel .owl-nav button.owl-prev,
.ApartamentySlider .owl-carousel .owl-nav button.owl-next {
  width: 30px;
  height: 30px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin: 20px 5px;
}

@media (min-width: 576px) {
  .ApartamentySlider .owl-carousel .owl-nav button.owl-prev,
  .ApartamentySlider .owl-carousel .owl-nav button.owl-next {
    margin: 20px;
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 992px) {
  .ApartamentySlider .owl-carousel .owl-nav button.owl-prev,
  .ApartamentySlider .owl-carousel .owl-nav button.owl-next {
    width: 60px;
    height: 60px;
  }
}

.ApartamentySlider .owl-carousel .owl-nav button.owl-prev:hover,
.ApartamentySlider .owl-carousel .owl-nav button.owl-next:hover {
  background-color: transparent;
}

.ApartamentySlider .owl-carousel .owl-nav button.owl-prev {
  background-image: url("../images/owl-left.png");
}

.ApartamentySlider .owl-carousel .owl-nav button.owl-next {
  background-image: url("../images/owl-right.png");
}

.Bialystok {
  margin-top: 4rem;
}

@media (min-width: 460px) {
  .Bialystok {
    padding-bottom: 1rem;
  }
}

.Bialystok__parallax {
  /* The image used */
  background-image: url("../images/biaystok-4660146_1920.jpg");
  /* Set a specific height */
  min-height: 700px;
  /* Create the parallax scrolling effect */
  background-attachment: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 4rem;
}

@media (min-width: 576px) {
  .Bialystok__parallax {
    background-attachment: fixed;
    -webkit-background-size: fixed;
    -moz-background-size: fixed;
  }
}

.Bialystok__Offer {
  margin-bottom: 2rem;
  padding-right: 3rem;
  padding-left: 3rem;
  background-image: url("../images/px_fiolet.png");
  background-position: left;
  background-repeat: repeat-y;
}

@media (min-width: 992px) {
  .Bialystok__Offer {
    margin-bottom: 0;
  }
}

.Bialystok__Offer__titleSection {
  margin-top: .6rem;
  margin-bottom: 3rem;
  color: #5C5D60;
  text-transform: capitalize;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  text-transform: none;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #5C5D60;
  font-weight: 700;
}

.Bialystok__Offer__titleSection a {
  color: #5C5D60;
}

@media (min-width: 576px) {
  .Bialystok__Offer__titleSection {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .Bialystok__Offer__titleSection {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .Bialystok__Offer__titleSection {
    font-size: 48px;
  }
}

@media (min-width: 576px) {
  .Bialystok__Offer__titleSection {
    padding-bottom: 0px;
  }
}

.Bialystok__Offer__subtitleSection {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-align: center;
  letter-spacing: 2px;
  line-height: 24px;
  color: #5C5D60;
  text-transform: uppercase;
  text-align: left;
}

@media (min-width: 576px) {
  .Bialystok__Offer__subtitleSection {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .Bialystok__Offer__subtitleSection {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .Bialystok__Offer__subtitleSection {
    font-size: 16px;
  }
}

.Bialystok__Offer__Title H3 {
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  text-transform: none;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #5C5D60;
  font-weight: 700;
  color: #5C5D60;
  padding-bottom: 2rem;
}

.Bialystok__Offer__Title H3 a {
  color: #5C5D60;
}

@media (min-width: 576px) {
  .Bialystok__Offer__Title H3 {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .Bialystok__Offer__Title H3 {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .Bialystok__Offer__Title H3 {
    font-size: 48px;
  }
}

.Bialystok__Offer__Text {
  padding-bottom: 2rem;
}

.Bialystok__Offer__Text p {
  font-size: 16px;
  font-weight: 300;
}

.Bialystok__Offer__Link a {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: flex;
  color: #5B5CD8;
}

.Bialystok__Offer__Link a img {
  padding-left: 10px;
}

.ApartamentyNaWynajem {
  margin-bottom: 3rem;
  margin-top: 4rem;
}

.ApartamentyNaWynajem ul {
  font-weight: 300;
  font-size: 16px;
  margin: 1rem 0 1rem 3rem;
}

.ApartamentyNaWynajem__Offer {
  padding-right: 3rem;
  padding-left: 3rem;
  background-image: url("../images/px_fiolet.png");
  background-position: left;
  background-repeat: repeat-y;
}

.ApartamentyNaWynajem__Offer__titleSection {
  margin-top: .6rem;
  margin-bottom: 3rem;
  color: #5C5D60;
  text-transform: capitalize;
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  text-transform: none;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #5C5D60;
  font-weight: 700;
}

.ApartamentyNaWynajem__Offer__titleSection a {
  color: #5C5D60;
}

@media (min-width: 576px) {
  .ApartamentyNaWynajem__Offer__titleSection {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .ApartamentyNaWynajem__Offer__titleSection {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .ApartamentyNaWynajem__Offer__titleSection {
    font-size: 48px;
  }
}

@media (min-width: 576px) {
  .ApartamentyNaWynajem__Offer__titleSection {
    padding-bottom: 0px;
  }
}

.ApartamentyNaWynajem__Offer__subtitleSection {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-align: center;
  letter-spacing: 2px;
  line-height: 24px;
  color: #5C5D60;
  text-transform: uppercase;
  text-align: left;
}

@media (min-width: 576px) {
  .ApartamentyNaWynajem__Offer__subtitleSection {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .ApartamentyNaWynajem__Offer__subtitleSection {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .ApartamentyNaWynajem__Offer__subtitleSection {
    font-size: 16px;
  }
}

.ApartamentyNaWynajem__Offer__Title H3 {
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  text-transform: none;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #5C5D60;
  font-weight: 700;
  color: #5C5D60;
  padding-bottom: 2rem;
}

.ApartamentyNaWynajem__Offer__Title H3 a {
  color: #5C5D60;
}

@media (min-width: 576px) {
  .ApartamentyNaWynajem__Offer__Title H3 {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .ApartamentyNaWynajem__Offer__Title H3 {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .ApartamentyNaWynajem__Offer__Title H3 {
    font-size: 48px;
  }
}

.ApartamentyNaWynajem__Offer__Text {
  padding-bottom: 2rem;
}

.ApartamentyNaWynajem__Offer__Text p {
  font-size: 16px;
  font-weight: 300;
}

.ApartamentyNaWynajem__Offer__Link a {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: flex;
  color: #5B5CD8;
}

.ApartamentyNaWynajem__Offer__Link a img {
  padding-left: 10px;
}

.ApartamentyLokalizacje {
  background-color: #E8E8E8;
  margin-top: 0;
  padding-top: 3rem;
}

.ApartamentyLokalizacje__titleSection {
  margin-top: .6rem;
  margin-bottom: 3rem;
  color: #5C5D60;
  text-transform: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  text-transform: none;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #5C5D60;
  font-weight: 700;
  text-align: center;
}

.ApartamentyLokalizacje__titleSection a {
  color: #5C5D60;
}

@media (min-width: 576px) {
  .ApartamentyLokalizacje__titleSection {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .ApartamentyLokalizacje__titleSection {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .ApartamentyLokalizacje__titleSection {
    font-size: 48px;
  }
}

@media (min-width: 576px) {
  .ApartamentyLokalizacje__titleSection {
    padding-bottom: 0px;
  }
}

.ApartamentyLokalizacje__subtitleSection {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-align: center;
  letter-spacing: 2px;
  line-height: 24px;
  color: #5C5D60;
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .ApartamentyLokalizacje__subtitleSection {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .ApartamentyLokalizacje__subtitleSection {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .ApartamentyLokalizacje__subtitleSection {
    font-size: 16px;
  }
}

.ApartamentyLokalizacje__item {
  height: 563px;
  width: 100%;
}

@media (max-width: 992px) {
  .ApartamentyLokalizacje__item__Left__desktop {
    display: none !important;
  }
}

@media (min-width: 993px) {
  .ApartamentyLokalizacje__item__Left__mobile {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .ApartamentyLokalizacje__item {
    height: unset;
  }
}

@media (min-width: 992px) {
  .ApartamentyLokalizacje__item {
    display: flex;
  }
}

.ApartamentyLokalizacje__item__picture {
  width: 50%;
}

.ApartamentyLokalizacje__item__picture .psuedo-background-img {
  position: relative;
  -o-object-fit: cover;
  object-fit: cover;
}

@media (max-width: 992px) {
  .ApartamentyLokalizacje__item__picture {
    width: 100%;
  }
}

.ApartamentyLokalizacje__item__textBoxBG {
  display: block;
}

.ApartamentyLokalizacje__item__textBoxBG__titleOffer {
  margin: 1rem 0;
  color: #5C5D60;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
  padding-right: 3rem;
}

@media (min-width: 576px) {
  .ApartamentyLokalizacje__item__textBoxBG__titleOffer {
    padding-bottom: 0px;
  }
}

.ApartamentyLokalizacje__item__textBoxBG__titleOffer a {
  color: #5c5d60;
}

.ApartamentyLokalizacje__item__textBoxBG__titleOffer a:hover {
  text-decoration: none;
  color: #000;
}

.ApartamentyLokalizacje__item__textBox {
  display: block;
  background: #fff;
  padding: 1.7rem;
  width: 50%;
}

@media (max-width: 992px) {
  .ApartamentyLokalizacje__item__textBox {
    width: 100%;
    margin-bottom: 2rem;
  }
}

.ApartamentyLokalizacje__item__textBox__opis p {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
}

@media (min-width: 993px) {
  .ApartamentyLokalizacje__item__textBox__opis p {
    height: 15rem;
  }
}

.ApartamentyLokalizacje__item__ikona {
  width: 27px !important;
  height: 38px;
  padding: 0;
  margin: 1rem auto;
}

.ApartamentyLokalizacje__item__LinkButton {
  margin: 3rem 0 0 0;
}

.ApartamentyLokalizacje__item__LinkButton a {
  width: 50px;
  height: 50px;
  display: block;
}

.ApartamentyLokalizacje__item__LinkButton a.linkArrow:hover {
  opacity: .5;
}

.ApartamentyLista {
  background-color: #E8E8E8;
  margin-top: 0;
  padding-top: 3rem;
}

.ApartamentyLista__titleSection {
  margin-top: .6rem;
  margin-bottom: 4rem;
  color: #5C5D60;
  text-transform: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  text-transform: none;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #5C5D60;
  font-weight: 700;
  text-align: center;
}

.ApartamentyLista__titleSection a {
  color: #5C5D60;
}

@media (min-width: 576px) {
  .ApartamentyLista__titleSection {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .ApartamentyLista__titleSection {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .ApartamentyLista__titleSection {
    font-size: 48px;
  }
}

@media (min-width: 576px) {
  .ApartamentyLista__titleSection {
    padding-bottom: 0px;
  }
}

.ApartamentyLista__subtitleSection {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-align: center;
  letter-spacing: 2px;
  line-height: 24px;
  color: #5C5D60;
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .ApartamentyLista__subtitleSection {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .ApartamentyLista__subtitleSection {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .ApartamentyLista__subtitleSection {
    font-size: 16px;
  }
}

.ApartamentyLista__the_content {
  background: #fff;
  margin: 0 0 2rem 0;
}

@media (min-width: 992px) {
  .ApartamentyLista__the_content {
    padding: 2rem 7rem;
  }
}

.ApartamentyLista__the_content p {
  margin: 1rem 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 200;
}

.ApartamentyLista__item {
  height: 436px;
  width: 100%;
  display: flex;
  padding: 0;
  margin-bottom: 2rem;
}

.ApartamentyLista__item__picture {
  padding: 0;
  max-height: 436px;
}

.ApartamentyLista__item__picture .psuedo-background-img {
  position: relative;
  -o-object-fit: cover;
  object-fit: cover;
}

.ApartamentyLista__item__textBoxBG {
  display: flex;
  margin-top: 3rem;
  margin-bottom: 3rem;
  align-items: center;
}

.ApartamentyLista__item__textBoxBG__titleOffer {
  color: #5C5D60;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-align: left;
}

@media (min-width: 576px) {
  .ApartamentyLista__item__textBoxBG__titleOffer {
    padding-bottom: 0px;
  }
}

.ApartamentyLista__item__textBox {
  display: block;
  background: #fff;
  padding: 1.7rem 2rem 2rem 2rem;
}

@media (min-width: 992px) {
  .ApartamentyLista__item__textBox {
    padding: 1.7rem 2rem 2rem 6rem;
  }
}

.ApartamentyLista__item__textBox__opis {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 200;
  height: 6rem;
}

.ApartamentyLista__item__ikona {
  width: 27px;
  height: 38px;
  padding: 0;
  margin-right: .5rem;
}

.ApartamentyLista__item__LinkButton {
  margin: 3rem 0 0 0;
  display: flex;
}

.ApartamentyLista__item__LinkButton a {
  width: 50px;
  height: 50px;
  display: block;
}

.ApartamentyLista__item__LinkButton a.rezerwuj {
  display: block;
  width: 126px;
  height: 50px;
  padding: 14px 0 7px 0;
  background: #5b5cd8;
  border: 1px solid #5b5cd8;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 3px;
  margin-right: .4rem;
}

.ApartamentyLista__item__LinkButton a.rezerwuj:hover {
  background: #fff;
  border: 1px solid #5b5cd8;
  color: #5b5cd8 !important;
  text-decoration: none;
}

.ApartamentyLista__item__LinkButton a.linkArrow:hover {
  opacity: .5;
}

.reservation {
  padding-top: 0px;
}

.iframe-loading {
  background: url("https://bookassistpolska.pl/_rangepicker/preloader.gif") center 100px no-repeat;
}

.pageRooms {
  background-color: #E8E8E8;
  margin-top: 0;
  padding-top: 3rem;
}

.pageRooms__titleSection {
  margin-top: .6rem;
  margin-bottom: 3rem;
  color: #5C5D60;
  text-transform: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  text-transform: none;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #5C5D60;
  font-weight: 700;
  text-align: center;
}

.pageRooms__titleSection a {
  color: #5C5D60;
}

@media (min-width: 576px) {
  .pageRooms__titleSection {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .pageRooms__titleSection {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .pageRooms__titleSection {
    font-size: 48px;
  }
}

@media (min-width: 576px) {
  .pageRooms__titleSection {
    padding-bottom: 0px;
  }
}

.pageRooms__subtitleSection {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-align: center;
  letter-spacing: 2px;
  line-height: 24px;
  color: #5C5D60;
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .pageRooms__subtitleSection {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .pageRooms__subtitleSection {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .pageRooms__subtitleSection {
    font-size: 16px;
  }
}

.pageRooms__Container {
  background: #fff;
}

.pageRooms__Container__icons {
  text-align: center;
  display: flex;
  align-items: center;
  width: 70%;
  margin: 3rem auto 2rem;
}

.pageRooms__Container__icons p {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin: 0 auto;
}

.pageRooms__Container__icons p img {
  width: 21px;
  height: 21px;
  margin-right: 10px;
}

@media (max-width: 400px) {
  .pageRooms__Container__icons p {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.pageRooms__Container__Opis {
  font-family: 'Montserrat', sans-serif;
  padding: 0 1rem;
  font-size: 16px;
  font-weight: 300;
}

@media (min-width: 992px) {
  .pageRooms__Container__Opis {
    padding: 0 6rem;
  }
}

.pageRooms__Container__Callendar {
  text-align: center;
}

.pageRooms__Container__Callendar img.logo {
  position: relative;
  top: 16px;
  z-index: 21;
  text-align: center;
}

.pageRooms__Container__Callendar .reservationPanel {
  background: #fff;
  margin: 0 auto;
  padding: 1rem;
  /* max-width: 235px;
				max-width: 285px; */
  max-width: 400px;
}

.pageRooms__Container__Callendar .reservationPanel.granatowy {
  border: 1px solid #0c41aa;
  display: block;
}

.pageRooms__Container__Callendar .reservationPanel.granatowy button {
  font-weight: 500;
  color: #fff;
  background-color: #0c41aa !important;
}

.pageRooms__Container__Callendar .reservationPanel.granatowy button {
  font-weight: 500;
  color: #fff;
  background-color: #0c41aa !important;
}

.pageRooms__Container__Callendar .reservationPanel.granatowy #calcurrent {
  background-color: #0c41aa !important;
  border: 1px solid #0c41aa !important;
}

.pageRooms__Container__Callendar .reservationPanel.granatowy #calroot {
  background-color: #0c41aa;
  border: 5px solid #0c41aa;
}

.pageRooms__Container__Callendar .reservationPanel.granatowy #calhead {
  background-color: #0c41aa;
}

.pageRooms__Container__Callendar .reservationPanel.rozowy {
  border: 1px solid #C61A59;
  display: block;
}

.pageRooms__Container__Callendar .reservationPanel.rozowy button {
  font-weight: 500;
  color: #fff;
  background-color: #C61A59 !important;
}

.pageRooms__Container__Callendar .reservationPanel.rozowy #calcurrent {
  background-color: #c61a59 !important;
  border: 1px solid #c61a59 !important;
}

.pageRooms__Container__Callendar .reservationPanel.rozowy #calroot {
  background-color: #c61a59;
  border: 5px solid #c61a59;
}

.pageRooms__Container__Callendar .reservationPanel.rozowy #calhead {
  background-color: #c61a59;
}

.pageRooms__Container__Callendar .reservationPanel.pomaranczowy {
  border: 1px solid #e8510f;
  display: block;
}

.pageRooms__Container__Callendar .reservationPanel.pomaranczowy button {
  font-weight: 500;
  color: #fff;
  background-color: #e8510f !important;
}

.pageRooms__Container__Callendar .reservationPanel.pomaranczowy #calcurrent {
  background-color: #e8510f !important;
  border: 1px solid #e8510f !important;
}

.pageRooms__Container__Callendar .reservationPanel.pomaranczowy #calroot {
  background-color: #e8510f;
  border: 5px solid #e8510f;
}

.pageRooms__Container__Callendar .reservationPanel.pomaranczowy #calhead {
  background-color: #e8510f;
}

.pageRooms__Container__Callendar .reservationPanel.zielony {
  border: 1px solid #0d8473;
  display: block;
}

.pageRooms__Container__Callendar .reservationPanel.zielony button {
  font-weight: 500;
  color: #fff;
  background-color: #0d8473 !important;
}

.pageRooms__Container__Callendar .reservationPanel.zielony #calcurrent {
  background-color: #0d8473 !important;
  border: 1px solid #0d8473 !important;
}

.pageRooms__Container__Callendar .reservationPanel.zielony #calroot {
  background-color: #0d8473;
  border: 5px solid #0d8473;
}

.pageRooms__Container__Callendar .reservationPanel.zielony #calhead {
  background-color: #0d8473;
}

.pageRooms__Container__Callendar .reservationPanel.zolty {
  border: 1px solid #f2a50a;
  display: block;
}

.pageRooms__Container__Callendar .reservationPanel.zolty button {
  font-weight: 500;
  color: #000;
  background-color: #f2a50a !important;
}

.pageRooms__Container__Callendar .reservationPanel.zolty #calcurrent {
  background-color: #f2a50a !important;
  border: 1px solid #f2a50a !important;
}

.pageRooms__Container__Callendar .reservationPanel.zolty #calroot {
  background-color: #f2a50a;
  border: 5px solid #f2a50a;
}

.pageRooms__Container__Callendar .reservationPanel.zolty #calhead {
  background-color: #f2a50a;
}

@media (min-width: 576px) {
  .pageRooms__Container__Callendar .reservationPanel {
    display: block;
  }
}

@media (max-width: 410px) {
  .pageRooms__Container__Callendar .reservationPanel {
    max-width: 335px;
  }
}

.pageRooms__Container__Callendar .reservationPanel .form-group {
  display: block;
  margin-bottom: .3rem;
  margin-bottom: .7rem;
  /* width: 200px;
					width: 260px; */
  width: 400px;
}

@media (max-width: 410px) {
  .pageRooms__Container__Callendar .reservationPanel .form-group {
    width: unset;
  }
}

.pageRooms__Container__Callendar .reservationPanel .-form {
  justify-content: center;
}

.pageRooms__Container__Callendar .reservationPanel .-form label {
  font-family: 'Montserrat', sans-serif;
  display: block;
  font-size: 8px;
  font-weight: 700;
  /* font-size: 12px; */
  font-size: 16px;
  font-weight: 500;
  color: #969696;
  padding: 5px 0 0 10px;
  text-align: left;
}

@media (max-width: 410px) {
  .pageRooms__Container__Callendar .reservationPanel .-form label {
    font-size: 14px;
  }
}

.pageRooms__Container__Callendar .reservationPanel .-form input, .pageRooms__Container__Callendar .reservationPanel .-form select {
  margin: 0;
  background-color: #E6E6E6;
  border: 0;
  outline: 0 !important;
  box-shadow: none !important;
  font-family: 'Montserrat', sans-serif;
  /* font-size: 12px; */
  font-size: 16px;
  font-weight: 500;
  color: #2D2D2D;
  border-radius: 0;
  padding: 0 10px 0 10px;
  padding: 20px 10px;
  cursor: pointer;
  width: 100%;
  min-height: 35px;
}

@media (max-width: 410px) {
  .pageRooms__Container__Callendar .reservationPanel .-form input, .pageRooms__Container__Callendar .reservationPanel .-form select {
    font-size: 14px;
  }
}

@media (min-width: 767px) {
  .pageRooms__Container__Callendar .reservationPanel .-form input, .pageRooms__Container__Callendar .reservationPanel .-form select {
    font-size: 16px;
  }
}

@media (min-width: 870px) {
  .pageRooms__Container__Callendar .reservationPanel .-form input, .pageRooms__Container__Callendar .reservationPanel .-form select {
    font-size: 16px;
  }
}

.pageRooms__Container__Callendar .reservationPanel .-form input:focus, .pageRooms__Container__Callendar .reservationPanel .-form select:focus {
  background-color: #fff;
  border: 0;
  outline: 0 !important;
  box-shadow: none !important;
  color: #000;
}

.pageRooms__Container__Callendar .reservationPanel .-form input.inputDateIn, .pageRooms__Container__Callendar .reservationPanel .-form select.inputDateIn {
  background-image: url("../images/kalendarz.svg");
  background-repeat: no-repeat;
  background-position: 90% center;
  background-size: 22px 22px;
}

.pageRooms__Container__Callendar .reservationPanel .-form input.inputDateOut, .pageRooms__Container__Callendar .reservationPanel .-form select.inputDateOut {
  background-image: url("../images/kalendarz.svg");
  background-repeat: no-repeat;
  background-position: 90% center;
  background-size: 22px 22px;
}

.pageRooms__Container__Callendar .reservationPanel .-form input.promocode, .pageRooms__Container__Callendar .reservationPanel .-form select.promocode {
  background-image: url("../images/promoCode_bg.png");
  background-repeat: no-repeat;
  background-position: 90% center;
}

.pageRooms__Container__Callendar .reservationPanel .-form select {
  /* font-family:  Lato,sans-serif; */
  font-family: 'Montserrat', sans-serif;
}

.pageRooms__Container__Callendar .reservationPanel .-form select option {
  padding: 5px;
}

.pageRooms__Container__Callendar .reservationPanel .-form button {
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  background-color: #C61A59;
  display: block !important;
  border-radius: 0;
  padding: 0;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 3px;
  width: 200px;
  height: 55px;
  margin: 20px auto 3px !important;
}

@media (max-width: 1200px) {
  .pageRooms__Container__Callendar .reservationPanel .-form button {
    width: 150px;
    height: 50px;
  }
}

.pageRooms__Container__Callendar .reservationPanel .-form button:before {
  border: none;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
}

.pageRooms__Udogodnienia {
  padding: 2rem 1rem;
}

@media (min-width: 992px) {
  .pageRooms__Udogodnienia {
    padding: 2rem 7rem;
  }
}

.pageRooms__Udogodnienia__title {
  margin-top: 4rem;
}

.pageRooms__Udogodnienia__title .ApartamentyLista__titleSection {
  margin-bottom: 1rem;
}

.pageRooms__Udogodnienia__Item {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #666;
  margin-bottom: 3rem;
}

.pageRooms__Udogodnienia__Item span.img {
  width: 100px;
  text-align: center;
}

.pageRooms__Udogodnienia__Item span.opis {
  text-align: left;
  width: 150px;
}

.pageRooms__Udogodnienia__Item img {
  width: auto;
  height: 44px;
}

.pageRooms__Udogodnienia__Items {
  display: flex;
  align-items: center;
}

.pageRooms__OtherLocations {
  margin-top: 4rem;
}

#gallery-carousel {
  position: relative;
  margin-top: 3rem;
}

#gallery-carousel .gallery-image img {
  max-height: 400px;
  width: auto;
}

#gallery-carousel .owl-theme .owl-nav {
  position: absolute;
  left: 0;
  right: 0;
  top: 40%;
}

#gallery-carousel .owl-theme .owl-nav [class*="owl-"] {
  color: #fff;
  font-size: 1.4375rem;
  margin: 0;
  padding: 5px;
  background: 0 0;
  display: inline-block;
  cursor: pointer;
  border-radius: 0;
  position: absolute;
  transition: all .4s ease 0s;
}

#gallery-carousel .owl-prev {
  left: 23.75vw;
}

#gallery-carousel .owl-next {
  right: 23.75vw;
}

#gallery-carousel .owl-prev svg {
  width: 4rem;
  height: 4rem;
  transform: rotate(180deg);
}

.pageContact {
  background-color: #E8E8E8;
  margin-top: 0;
  padding-top: 3rem;
}

.pageContact__titleSection {
  margin-top: .6rem;
  margin-bottom: 3rem;
  color: #5C5D60;
  text-transform: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  text-transform: none;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #5C5D60;
  font-weight: 700;
  text-align: center;
}

.pageContact__titleSection a {
  color: #5C5D60;
}

@media (min-width: 576px) {
  .pageContact__titleSection {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .pageContact__titleSection {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .pageContact__titleSection {
    font-size: 48px;
  }
}

@media (min-width: 576px) {
  .pageContact__titleSection {
    padding-bottom: 0px;
  }
}

.pageContact__subtitleSection {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-align: center;
  letter-spacing: 2px;
  line-height: 24px;
  color: #5C5D60;
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .pageContact__subtitleSection {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .pageContact__subtitleSection {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .pageContact__subtitleSection {
    font-size: 16px;
  }
}

.pageContact__Container {
  background: #fff;
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 300;
  margin: 1rem auto 2rem;
}

@media (min-width: 993px) {
  .pageContact__Container {
    padding-left: 8rem;
    padding-right: 8rem;
  }
}

.pageContact__Container .textWrap .textBox a {
  color: #000;
}

.pageContact__Container .icon {
  width: 50px;
}

@media (max-width: 992px) {
  .pageContact__Container .icon {
    width: 20px;
  }
}

.pageContact__Container .icon img {
  width: 50px;
}

@media (max-width: 992px) {
  .pageContact__Container .icon img {
    width: 20px;
  }
}

.pageContact__Container .text p {
  padding-top: 2px !important;
  text-align: left !important;
  font-size: 15px !important;
}

.pageContact__Container .text p + p {
  padding-top: 2px;
}

.formContact {
  background-color: #e2e1df;
  margin-top: unset !important;
}

.locationKontakt {
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
}

.locationKontakt .nazwa {
  font-size: 16px;
  font-weight: 400;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.locationKontakt .opis p {
  font-size: 13px;
}

.locationKontakt .opis .icon {
  width: 50px;
}

.locationKontakt .opis .icon img {
  width: 50px;
}

.locationKontakt .link_do_strony {
  text-align: center;
}

.locationKontakt .link_do_strony a {
  text-transform: uppercase;
}

.locationKontakt .odleglosci {
  padding: 10px 0;
}

.pageGallery #filters {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media (max-width: 450px) {
  .pageGallery #filters {
    flex-direction: column;
    text-align: center;
  }
}

.pageGallery #filters .button {
  color: #5c5d60;
  margin: 4px 15px;
  min-height: 35px;
  font-size: 16px;
}

@media (min-width: 992px) {
  .pageGallery #filters .button {
    font-size: 16px;
    margin: 8px 15px;
  }
}

@media (min-width: 1200px) {
  .pageGallery #filters .button {
    font-size: 18px;
  }
}

.pageGallery #filters .button::after {
  content: '';
  opacity: 0;
  width: 100%;
  height: 2px;
  background-color: #eaeaea;
  margin-top: 3px;
  transition: .3s;
  display: block;
}

.pageGallery #filters .button.activeItem::after {
  opacity: 1;
}

.pageGallery #filters .button:hover {
  cursor: pointer;
}

.pageGallery .grid {
  min-height: 500px;
}

.pageGallery .grid .element-item {
  margin-bottom: 20px;
  height: auto;
  position: relative;
  overflow: hidden;
  height: 300px;
}

@media (max-width: 768px) {
  .pageGallery .grid .element-item {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .pageGallery .grid .element-item {
    width: 350px;
  }
}

@media (min-width: 1200px) {
  .pageGallery .grid .element-item {
    width: 400px;
  }
}

@media (min-width: 1400px) {
  .pageGallery .grid .element-item {
    width: 440px;
  }
}

.pageGallery .grid .element-item img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  margin: 0 20px;
}

.SingleSpecialOffer {
  background-color: #E8E8E8;
  margin-top: 0;
  padding-top: 3rem;
}

.SingleSpecialOffer__titleSection {
  margin-top: .6rem;
  margin-bottom: 3rem;
  color: #5C5D60;
  text-transform: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  text-transform: none;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #5C5D60;
  font-weight: 700;
  text-align: center;
}

.SingleSpecialOffer__titleSection a {
  color: #5C5D60;
}

@media (min-width: 576px) {
  .SingleSpecialOffer__titleSection {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .SingleSpecialOffer__titleSection {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .SingleSpecialOffer__titleSection {
    font-size: 48px;
  }
}

@media (min-width: 576px) {
  .SingleSpecialOffer__titleSection {
    padding-bottom: 0px;
  }
}

.SingleSpecialOffer__subtitleSection {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-align: center;
  letter-spacing: 2px;
  line-height: 24px;
  color: #5C5D60;
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .SingleSpecialOffer__subtitleSection {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .SingleSpecialOffer__subtitleSection {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .SingleSpecialOffer__subtitleSection {
    font-size: 16px;
  }
}

.SingleSpecialOffer__Container {
  background: #fff;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.SingleSpecialOffer__Container__Opis {
  font-family: 'Montserrat', sans-serif;
  padding: 0 6rem;
  font-size: 14px;
  font-weight: 300;
  margin: 1rem auto 2rem;
}

.SingleSpecialOffer__Container__Opis__krotki {
  margin: 10px auto;
}

.SingleSpecialOffer__Container svg {
  height: 50px;
  width: auto;
  margin: 0 10px;
}

.PageStandard {
  background-color: #E8E8E8;
  margin-top: 0;
  padding-top: 3rem;
}

.PageStandard__titleSection {
  margin-top: .6rem;
  margin-bottom: 3rem;
  color: #5C5D60;
  text-transform: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  text-transform: none;
  font-weight: 700;
  margin-bottom: 3rem;
  color: #5C5D60;
  font-weight: 700;
  text-align: center;
}

.PageStandard__titleSection a {
  color: #5C5D60;
}

@media (min-width: 576px) {
  .PageStandard__titleSection {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .PageStandard__titleSection {
    font-size: 44px;
  }
}

@media (min-width: 992px) {
  .PageStandard__titleSection {
    font-size: 48px;
  }
}

@media (min-width: 576px) {
  .PageStandard__titleSection {
    padding-bottom: 0px;
  }
}

.PageStandard__subtitleSection {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-align: center;
  letter-spacing: 2px;
  line-height: 24px;
  color: #5C5D60;
  text-transform: uppercase;
}

@media (min-width: 576px) {
  .PageStandard__subtitleSection {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .PageStandard__subtitleSection {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  .PageStandard__subtitleSection {
    font-size: 16px;
  }
}

.PageStandard__Container {
  background: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 300;
  margin: 1rem auto 2rem;
}

@media (min-width: 992px) {
  .PageStandard__Container {
    padding: 3rem 5rem;
  }
}

.PageStandard__Container ol, .PageStandard__Container p {
  margin: 1rem 0;
}

.PageStandard__Container h1, .PageStandard__Container h2, .PageStandard__Container h3, .PageStandard__Container h4, .PageStandard__Container h5, .PageStandard__Container h6 {
  margin: 2rem 0 1rem;
}

.PageStandard__Container dl, .PageStandard__Container ol, .PageStandard__Container ul {
  margin: 2rem;
  line-height: 1.8rem;
}
