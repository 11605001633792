.container-fluid-paralax{width: 100% !important;}
.listOfferTextSlider {padding-bottom:0}

.ApartamentySlider {
	padding-bottom: 0;
    @include SectionMargin;
    &__titleSection{
		@include HeadFont48;
        @media(min-width:576px){
            padding-bottom:0px;
        } 
        text-align: center;
        margin-bottom: 5rem;
    }	 
	&__subtitleSection{
		@include SubtitleFont14; 
	}
	 
   &__item{
        height:563px;
        background-size:cover;
        background-position:center;
        background-repeat: no-repeat;
        position:relative;
        width: 330px;

        @media(min-width:576px){
            height:563px;
        }

        @media(min-width:768px){
            height:563px;
        }

        @media(min-width:992px){
            height:563px;
        }

        @media(min-width:1200px){
            height:563px;
        }

        @media(min-width:1400px){
            height:563px;
        }

        @media(min-width:1600px){
            height:563px;
        }
        
        /* @include overlay(#000, 0.0); */

        &.onMobile{
            display:block;

            @media(min-width:768px){
                display:none;
            }
        }

        &.onDesktop{
            display:none;

            @media(min-width:768px){
                display:block;
            }
        }

        &.onMobile.onDesktop{
            display:block;
        }
        &__textBoxBG {
            display: flex;
            &__titleOffer{                
                margin-top: .6rem;
                margin-left:0.6rem;
                color: $headers;
                /* text-transform: capitalize; */
                @include LatoFontRegular;
                @include BodyFont;
                font-weight: 400;
                font-size: 14px;
                @media(min-width:576px){
                    padding-bottom:0px;
                } 
                text-align: left;
                padding-right: 2rem;
                a{
                    color: #5c5d60;
                    &:hover{
                        text-decoration: none;
                        color: #000;
                    }
                }
            }
        }
        &__textBox {
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            width: 270px;
            height: 87px;
            background: #fff;
            padding: 1rem;
        }

        &__ikona {
            width: 27px!important;
            height: 38px;
            padding: 0;
            margin: 0.5rem;
        }

        &__LinkButton{
            a{
                width: 50px;
                height: 50px;
                display: block;
                z-index: 1111111111;
            }
            position: absolute;
            right: 0;
            bottom: 0;
            margin: 0;
        }


        &.onMobile{
            display:block;

            @media(min-width:768px){
                display:none;
            }
        }

        &.onDesktop{
            display:none;

            @media(min-width:768px){
                display:block;
            }
        }

        &.onMobile.onDesktop{
            display:block;
        }

    }

    .owl-theme .owl-nav{
        width: 100%;
        max-width:1630px;
        left:50%;
        position: absolute;
        top: 50%;
        transform:translate(-50%,-50%);
        display: flex;
        justify-content: space-between;

        height:0;
        margin-top:-40px;
    }

    .owl-theme .owl-dots{
        position: absolute;
        bottom: 10px;
        text-align: center;
        margin: 0 auto;
        left: 50%;
        transform: translate(-50%);
    }

    .owl-theme .owl-dots .owl-dot span{
        margin: 5px 4px;
        background-color: #fff;
    }

    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
        background-color: #D6D6D6;
    }
    

    .owl-carousel .owl-nav button.owl-prev,
    .owl-carousel .owl-nav button.owl-next{
        width:30px;
        height:30px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin: 20px 5px;

        @media(min-width:576px){
            margin:20px;
            width:40px;
            height:40px;
        }

        @media(min-width:992px){
            width:60px;
            height:60px;
        }

        &:hover{
            background-color:transparent;
        }
    }

    .owl-carousel .owl-nav button.owl-prev{
        background-image: url('../images/owl-left.png');
    }

    .owl-carousel .owl-nav button.owl-next{
        background-image: url('../images/owl-right.png');
    }

}
