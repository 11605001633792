.page-id-2893 .welcome,
.page-template-page-galeria .welcome{
	background: none;
}
.welcome{
	background-color: $welcomeBackgroundColor;
	background: url('../images/angelhouse-napis.svg');
	background-repeat: no-repeat;
	background-position: center; 

	.titleSection{
	
        padding-bottom:0px;
		  color: $apartamentyGold;	
        @include headFontBold;  
        @media(min-width:576px){
            padding-bottom:0px;
        } 
		margin-top: .6rem;
        margin-bottom:3rem;
		color: $headers;
		text-transform: none;
		@include HeadFont48;
		font-weight: 700;
        @media(min-width:576px){
            padding-bottom:0px;
        } 
        text-align: center;
	}
	.subtitleSection{
		@include SubtitleFont14; 
	}

    .textWrap{
        max-width:930px;
        margin:0 auto;
        text-align: justify;

        p+p{
            padding-top:15px;
        }
    }
	 img {
        @media(max-width:992px){
            padding-top:30px;
        } 
	 }
}   