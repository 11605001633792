
.ApartamentyNaWynajem {
	ul{
		font-weight: 300;
		@include BodyFont;
		margin: 1rem 0 1rem 3rem;
	}
    margin-bottom: 3rem;
    @include SectionMargin;
	&__Offer {		
        padding-right: 3rem;
        padding-left: 3rem;
        background-image: url('../images/px_fiolet.png');
        background-position: left;
        background-repeat: repeat-y;
    
        &__titleSection{
            margin-top: .6rem;
            margin-bottom:3rem;
            color: $headers;
            text-transform: capitalize;
            text-align: left;
            @include HeadFont48;
            font-weight: 700;
            @media(min-width:576px){
                padding-bottom:0px;
            } 
        }	 

        &__subtitleSection{
            @include SubtitleFont14;
            text-align: left;
        }
        
		&__Title H3{
			@include HeadFont48;
			font-weight: 700;
			color: $headers;
			padding-bottom: 2rem;
		}

		&__Text{
			p {
                @include BodyFont;
				font-weight: 300;
			}
			padding-bottom: 2rem;
		}

		&__Link{
			a {
				@include MontserratFont; 
				font-size: 14px;
				font-weight: 700;
				letter-spacing: 2px;
				text-transform: uppercase;
				display: flex;
				color: $fioletowy;
				img {
					padding-left: 10px;
				}
			}

		}
	}
	
}