.header{
	width: 100%!important;
    background-color: #fff;    
    position: absolute;
    top: 0;
    left: 0;
    z-index: 30;

    @media(min-width:992px){
        background-color: #fff;    
    }

// RP
    &__calltoUs {
        background: #5B5CD8 0% 0% no-repeat padding-box;
        opacity: 1;
        display: none;
        width: 120px;
        height: 82px;
        margin-left: auto;
        @include media(xsmall){
            display: block;            
        }
        @include media(large){
            width: 159px;
            height: 82px;
        }

        @include media(xlarge){
            width: 159px;
            height: 82px;
        }
        a{
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: center;
            p {
                color: #ffffff;
                text-align: left;
                letter-spacing: 0px;
                color: #FFFFFF;
                opacity: 1;
                font-size: 10px;
                @include media(large){
                    font-size: 14px;
                }
                padding: 0 0 0 10px;
            }
            svg {
                color: #fff;
            }
        }
        &:hover{
            text-decoration :none;
        }
        svg {
            max-width: 12px;
            height: auto;
        }
    }
    &__language {
        display: flex;
        margin: 0 0 0 15px;
        width: 15px;
        &Link:nth-child(2) {
            display:none;
        }
        display:none;
    }
    .navbar-brand{
        svg{
            .a{fill:#6ed2ba;}
            .b{fill:#5b5cd8;}
        }
    }
    .LogoTopHeaderFiolet{
        display: block;
        width: 200px;
        height: auto;
    }
    .LogoTopHeaderWhite{
        display: none;
    }
    //// HEADER FIXED 

    &--fixed{
        
        .LogoTopHeaderFiolet{
            display: none;
        }
        .LogoTopHeaderWhite{
            display: block;
            width: 200px;
            height: auto;
        }
        position: fixed;
        top: 0;
        left: 0;
        z-index: 100;
        background-color: rgba(91, 92, 216, 0.8);

        .navbar-brand{
            svg{
                .a{fill:#6ed2ba;}
                .b{fill:#fff;}
            }
        }

        .navbar{
            padding-top:0;
            padding-bottom:0;
        }

        .dropdown-menu{
            margin-top:0 !important;   

            @include media(large){
                background-color: rgba(152, 142, 111, .8) !important;
            }
        }
        .burger{        
            span{
                background-color: #fff;
            }
        }
    }
    //// END HEADER FIXED 

    
}