.mainSlider{
		height: calc(100vh);
		min-height: 650px;
        padding-top:0 !important;

    .item{
		height: calc(100vh);
		min-height: 400px;
        background-size:cover;
        background-position:center;
        background-repeat: no-repeat;
        position:relative;

        @media(min-width:576px){
				min-height: 500px;
        }

        @media(min-width:768px){
				min-height: 550px;
        }

        @media(min-width:992px){
				min-height: 550px;
        }

        @media(min-width:1200px){
				min-height: 550px;
        }

        @media(min-width:1400px){
				min-height: 600px;
        }

        @media(min-width:1600px){
            min-height:700px;
        }
        
        @include overlay(#000, .4);

        

        .textBox{
            position: absolute;
            position: absolute;
            top: 42%;
            left: 50%;
            transform: translate(-50%,-50%);/* 
            @media(min-width:992px){
                width: 75%;            
            } */
            .mainText{
                color:#fff;
                @include HeaderSliderFirst;
                font-size:22px;
                @include media(small){
                    font-size:36px;
                }        
                @include media(large){
                    font-size:48px;
                }
        
                @include media(xlarge){
                    font-size:52px;
                }
                font-weight: 900;
                text-shadow: 4px 4px 4px rgba(0,0,0,0.3);
                text-transform: inherit;
                letter-spacing: 8px;
            }
    
            .secondText{
                color:#fff;
                @include HeaderSliderSecond; 
                font-size:26px;
                font-weight: 900;
                text-shadow: 4px 4px 4px rgba(0,0,0,0.3);
                padding-top:5px;
                letter-spacing: 4px;
                p{
                    margin-top:1rem;
                    font-size:16px;
                    @include media(small){
                        font-size:20px;
                    }        
                    @include media(large){
                        font-size:20px;
                    }
                    @include media(xlarge){
                        font-size:26px;
                    }
                }


            }



            .btnBox{
                padding-top:5px;
                @media(min-width:768px){
                    padding-top:20px;
                }
            }

        }

        display:none;

        &.onMobile{
            display:block;

            @media(min-width:768px){
                display:none;
            }
        }

        &.onDesktop{
            display:none;

            @media(min-width:768px){
                display:block;
            }
        }

        &.onMobile.onDesktop{
            display:block;
        }

    }

    .owl-theme .owl-nav{
        width: 100%;
        max-width:1630px;
        left:50%;
        position: absolute;
        top: 50%;
        transform:translate(-50%,-50%);
        display: flex;
        justify-content: space-between;

        height:0;
        margin-top:-40px;
    }

    .owl-theme .owl-dots{
        position: absolute;
        bottom: 10px;
        text-align: center;
        margin: 0 auto;
        left: 50%;
        transform: translate(-50%);
    }

    .owl-theme .owl-dots .owl-dot span{
        margin: 5px 4px;
        background-color: #fff;
    }

    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{
        background-color: #D6D6D6;
    }
    

    .owl-carousel .owl-nav button.owl-prev,
    .owl-carousel .owl-nav button.owl-next{
        width:30px;
        height:30px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin: 20px 5px;

        @media(min-width:576px){
            margin:20px;
            width:40px;
            height:40px;
        }

        @media(min-width:992px){
            width:60px;
            height:60px;
        }

        &:hover{
            background-color:transparent;
        }
    }

    .owl-carousel .owl-nav button.owl-prev{
        background-image: url('../images/owl-left.png');
    }

    .owl-carousel .owl-nav button.owl-next{
        background-image: url('../images/owl-right.png');
    }

    
   
}