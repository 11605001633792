
.Opinions {    
    @include SectionMargin;
    &__titleSection{
        margin-top: .6rem;
        margin-bottom:3rem;
        color: $headers;
        text-transform: capitalize;
        text-align: center;
        @include HeadFont48;
        font-weight: 700;
        @media(min-width:576px){
            padding-bottom:0px;
        } 
    }	 

    &__subtitleSection{
        @include SubtitleFont14;
    }
}
.OpinionsSlider {
	&__Item {		
        &__Body{
            padding: 4rem 2rem;
            margin: 0 1rem;
            background-color: #D3F1F2;
            &__author p{
                @include BodyFont;
                font-weight: 700;
                color: #000000;
                margin-bottom: 0.5rem;
                text-align: center;
                letter-spacing: 2px;
            }
            img {
                text-align:center;
                width: 20px;
                height: 4px;
                margin: 0 auto;
                margin-bottom:.4rem;
            }
            &__Text{
                p {
                    @include BodyFont;
                    font-weight: 300;
                }
                padding-bottom: 2rem;
            }

            &__Link{
                a {
                    @include RobotoMedium; 
                    font-size: 14px;
                    text-transform: uppercase;
                    display: flex;
                    color: $fioletowy;
                    img {
                        padding-left: 10px;
                    }
                }

            }        
        }
	}
	
}