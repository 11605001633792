.ApartamentyLokalizacje {
    
	background-color: #E8E8E8;
    margin-top: 0;
    padding-top: 3rem;
    &__titleSection{
		margin-top: .6rem;
        margin-bottom:3rem;
		color: $headers;
		text-transform: none;
		@include HeadFont48;
		font-weight: 700;
        @media(min-width:576px){
            padding-bottom:0px;
        } 
        text-align: center;
    }	 
	&__subtitleSection{
		@include SubtitleFont14;
	}
   &__item{
       &__Left{
           &__desktop{
                @media(max-width:992px){
                    display: none!important;
                }
           }
           &__mobile{
                @media(min-width:993px){
                    display: none!important;
                }
           }
       }
        height:563px;
        @media(max-width:992px){
            height: unset;
        }  
        width: 100%;
        @media(min-width:992px){
            display: flex;
        }  
        &__picture {
            .psuedo-background-img {
                position: relative;
                -o-object-fit: cover;
                object-fit: cover;
            }
            width: 50%;
            @media(max-width:992px){
                width: 100%;
            }  
        }
        &__textBoxBG {
            display: block;
            &__titleOffer{                
                margin: 1rem 0;
                color: $headers;
                text-transform: uppercase;
                @include MontserratFont;
                font-size: 18px;
                font-weight: 300;
                @media(min-width:576px){
                    padding-bottom:0px;
                } 
                text-align: left;
                padding-right: 3rem;
                a{
                    color: #5c5d60;
                    &:hover{
                        text-decoration: none;
                        color: #000;
                    }
                }
            }
        }
        &__textBox {
            display: block;
            background: #fff;
            padding: 1.7rem;
            width: 50%;
            @media(max-width:992px){
                width: 100%;
                margin-bottom: 2rem;
            }  
            &__opis p{
                @include MontserratFont; 
                @include BodyFont;
                font-weight: 300;
                @media(min-width:993px){
                    height: 15rem;
                }  
                line-height: 24px;
            }
        }

        &__ikona {
            width: 27px!important;
            height: 38px;
            padding: 0;
            margin: 1rem auto;
        }

        &__LinkButton{
            a{
                width: 50px;
                height: 50px;
                display: block;
                &.linkArrow{
                    @include ArrowHover;
                }
            }
            margin: 3rem 0 0 0;
        }

    }


}
